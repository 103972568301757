import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Modal,
  Radio,
  Row,
  Typography,
  message,
} from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { UpdateSchoolInput, useUpdateSchoolMutation } from '~/alpha/core'
import { useAlphaStore } from '~/context'
import { useEmailPhoneStore } from '~/context/user'
import { useSchoolMeApiQuery } from '~/hooks/useSchoolMeApiQuery'
import { checkIfValidPhone } from '~/school-utils/validations'
import { AWS_S3_URL } from '~/utils/constants'
import QuestionTooltip from '../cards/QuestionTooltip'
import SchoolHeader from '../cards/SchoolHeader'
import CompletionLayout from '../layout/CompletionLayout'
import { Dashboard } from '../layout/Dashboard'
import SimpleVideoModal from '../modal/SimpleVideoModal'

interface ISettingForm {
  telephone: string
  email: string

  /**
   * 0: false - not active
   * 1: true - active
   */
  isStudentInputActive: '0' | '1'
  /**
   * 0 - quality is low
   * 1 - quality is high
   */
  videoQualitySetting: '0' | '1'
}

interface IAdviseMovie {
  url: string
  thumb: string
  title?: string
  desc?: string
}

const { Text } = Typography

const lowHighQualityVideos: IAdviseMovie[] = [
  {
    url: `${AWS_S3_URL}quality-video/low.mp4`,
    thumb: '/images/importance_mess_thumb.png',
  },
  {
    url: `${AWS_S3_URL}quality-video/high.mp4`,
    thumb: '/images/importance_mess_thumb.png',
  },
] as const

/**
 * Path: /setting
 */
const Setting = () => {
  const { t } = useTranslation()

  const { school, teacher, setTeacher, setShowUnloadPopup, nextUrl } =
    useAlphaStore()

  const { setPhone, setEmail } = useEmailPhoneStore()

  const prefectureCode = school?.attributes?.prefectureCode

  const [showSavedNotification, setShowSavedNotification] = useState(false)
  const {
    data: teacherData,
    loading: teacherLoading,
    refresh: refreshTeacherData,
  } = useSchoolMeApiQuery()

  const [updateSchoolMutation, { loading }] = useUpdateSchoolMutation()

  const [form] = Form.useForm<ISettingForm>()

  const history = useHistory()
  const [showSavingPopup, setShowSavingPopup] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState<IAdviseMovie | undefined>()

  const formRef = useRef<FormInstance<ISettingForm>>()
  const schoolRef = useRef<typeof school>()

  useEffect(() => {
    if (!school) {
      return
    }

    /**
     * To check if there is any change in the form
     * and if there is any change then show the popup
     * when the user tries to leave the page
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
     */
    const unloadCallback = (event) => {
      const values = form.getFieldsValue()
      const isStudentInputActive = values.isStudentInputActive === '1'
      // true means quality is low ('0' - low quality)
      const videoQualitySetting = values.videoQualitySetting === '0'
      if (
        school &&
        values.isStudentInputActive &&
        (values.telephone !== school.attributes.telephone ||
          values.email !== school.attributes.email ||
          isStudentInputActive !== school.attributes.isStudentInputActive ||
          videoQualitySetting !== school.attributes.videoQualitySetting)
      ) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', unloadCallback)
    formRef.current = form
    schoolRef.current = school
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [form, school])

  // Unmount event
  useEffect(() => {
    setShowUnloadPopup(() => {
      const values = formRef.current!.getFieldsValue()
      const school = schoolRef.current
      const isStudentInputActive = values.isStudentInputActive === '1'
      // true means quality is low ('0' - low quality)
      const videoQualitySetting = values.videoQualitySetting === '0'

      const hasChanged =
        school &&
        values.isStudentInputActive &&
        (values.telephone !== school.attributes.telephone ||
          values.email !== school.attributes.email ||
          isStudentInputActive !== school.attributes.isStudentInputActive ||
          videoQualitySetting !== school.attributes.videoQualitySetting)

      if (hasChanged) {
        setShowSavingPopup(hasChanged)
      }
      return hasChanged
    })
  }, [setShowUnloadPopup])

  // Initialize form with teacher data
  useEffect(() => {
    if (teacherLoading || !teacherData?.school) return

    const schoolData = teacherData.school
    form.setFieldsValue({
      email: schoolData.attributes.email,
      telephone: schoolData.attributes.telephone,
      isStudentInputActive: schoolData.attributes.isStudentInputActive
        ? '1'
        : '0',
      // true means quality is low ('0' - low quality)
      videoQualitySetting: schoolData.attributes.videoQualitySetting
        ? '0'
        : '1',
    })
  }, [teacherLoading, teacherData, form])

  const onFinish = async (values: {
    email: string
    telephone: string
    isStudentInputActive: '1' | '0'
    videoQualitySetting: '1' | '0'
  }) => {
    if (!school || prefectureCode == null) {
      message.error(`${t('エラーが発生しました。')} School is not available!`)
      return
    }

    if (!teacher) {
      message.error(`${t('エラーが発生しました。')} Teacher is not available!`)
      return
    }

    const isStudentInputActive = values.isStudentInputActive === '1'
    const videoQualitySetting = values.videoQualitySetting === '0'

    const variables: { input: UpdateSchoolInput } = {
      input: {
        schoolId: school._id,
        attributes: {
          ...values,
          isStudentInputActive,
          videoQualitySetting,
        },
      },
    }

    try {
      const res = await updateSchoolMutation({ variables })
      if (res.errors) {
        message.error(
          `${t('エラーが発生しました。')} [${res.errors[0]?.message}]`,
        )
        console.error('res.errors:', res.errors)
        return
      }

      const updateSchool = res.data?.updateSchool
      if (!updateSchool?.success) {
        message.error(`${t('エラーが発生しました。')} [${updateSchool?.error}]`)
        console.error('updateSchool?.error:', updateSchool?.error)
        return
      }

      // successfully updated
      setTeacher({
        ...teacher,
        school: {
          ...teacher.school,
          attributes: {
            ...teacher.school.attributes,
            ...values,
            isStudentInputActive,
            videoQualitySetting,
          },
        },
      })

      setPhone(values.telephone)
      setEmail(values.email)

      setShowSavedNotification(true)
      setTimeout(() => {
        setShowSavedNotification(false)
        history.push('/home')
      }, 2000)

      refreshTeacherData()
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${JSON.stringify(err)}]`)
      console.error('when updateSchoolMutation, error:', err)
    }
  }

  return (
    <>
      {school && teacher ? (
        <Dashboard selectedMenu={8} navbar={t('設定')}>
          {showSavedNotification ? (
            <CompletionLayout message={t('登録完了')} />
          ) : (
            <>
              <SchoolHeader school={school} teacher={teacher} />

              <div className="flex justify-center pt-2 pb-4">
                <Form
                  form={form}
                  layout="vertical"
                  className="max-w-400"
                  initialValues={{
                    email: school.attributes.email,
                    telephone: school.attributes.telephone,
                    isStudentInputActive: school.attributes.isStudentInputActive
                      ? '1'
                      : '0',
                    videoQualitySetting: school.attributes.videoQualitySetting
                      ? '0'
                      : '1',
                  }}
                  onFinish={onFinish}
                  requiredMark={false}
                >
                  <Row gutter={10}>
                    <Col span={24}>
                      <Form.Item
                        name="telephone"
                        label={
                          <>
                            <Text className="text-xs font-bold">
                              {t('学校電話番号')}
                            </Text>
                            <Button
                              type="primary"
                              className="ant-btn-ti ml-2"
                              danger
                            >
                              <div className="h-full flex justify-items-center items-center">
                                {t('必須')}
                              </div>
                            </Button>
                          </>
                        }
                        rules={[
                          {
                            required: true,
                            message: t('学校電話番号を入力して下さい。'),
                          },
                          () => ({
                            validator(_, value) {
                              if (!value || checkIfValidPhone(value)) {
                                return Promise.resolve()
                              }

                              return Promise.reject(
                                new Error(
                                  t(
                                    '電話番号を半角数字で正しく入力してください。',
                                  ),
                                ),
                              )
                            },
                          }),
                        ]}
                      >
                        <Input className="bg-gray-150" disabled={loading} />
                      </Form.Item>
                      <div className="flex flex-col" style={{ width: '120%' }}>
                        <div className="flex">
                          <Text className="text-xs font-bold">
                            {t('学校代表者メールアドレス')}
                          </Text>
                          <Button
                            type="primary"
                            className="ant-btn-ti ml-2"
                            danger
                          >
                            <div className="h-full flex justify-items-center items-center">
                              {t('必須')}
                            </div>
                          </Button>
                        </div>
                        <Text className="pt-1 ant-table-column-sorter-down">
                          ALPHA担当者より重要なお知らせをお送りすることがあります。<br />
                          ページ下部の「登録」ボタン押下後、登録完了メールが届いていることを確認してください。<br />
                          ※メールアドレスの登録・変更を行った場合のみ送信されます。
                        </Text>
                      </div>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t(
                              '学校代表者メールアドレスを入力して下さい。',
                            ),
                          },
                          {
                            type: 'email',
                            message: t(
                              '学校代表者メールアドレスを正しく入力してください。',
                            ),
                          },
                        ]}
                      >
                        <Input className="bg-gray-150" disabled={loading} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="isStudentInputActive"
                        label={
                          <>
                            <Text className="text-xs font-bold">
                              {t('児童・生徒ALPHAでの測定結果の入力設定')}
                            </Text>

                            <QuestionTooltip
                              TitleChild={
                                <span className="p-2">
                                  {t(
                                    '入力可：児童・生徒ALPHAで記録の入力・編集・削除を行うことができます。',
                                  )}
                                  <br />
                                  {t(
                                    '入力不可：児童・生徒ALPHAで記録の入力・編集・削除を行うことができません。',
                                  )}
                                  <br />※
                                  {t(
                                    '学校の記録を確定させたい場合などに「入力不可」にご変更ください。',
                                  )}
                                  <br />
                                </span>
                              }
                            />
                          </>
                        }
                      >
                        <Radio.Group>
                          <Radio key="input-on" value="1" checked={true}>
                            {t('入力可')}
                          </Radio>
                          <Radio key="input-off" value="0">
                            {t('入力不可')}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        // name="blankUnused"
                        label={
                          <>
                            <Text className="text-xs font-bold">
                              {t('児童・生徒の入力必須項目変更')}
                            </Text>

                            <QuestionTooltip
                              TitleChild={
                                <span className="p-2">
                                  {t(
                                    'ホーム画面の体力テスト入力済人数及び体力テスト入力画面の登録状況は、身長・体重及び体力テスト各種目の入力が完了している場合に「済」としてカウントされます。以下の場合は、学校の状況に応じて「済」の条件を本設定より変更することができます。',
                                  )}
                                  <br />　{t('例）')}
                                  <br />
                                  　　{t('身長・体重の入力は必要ない場合')}
                                  <br />
                                  　　
                                  {t(
                                    '体力テストは一部の種目のみの入力で問題ない場合',
                                  )}
                                  <br />
                                </span>
                              }
                            />
                          </>
                        }
                      >
                        <div className="flex justify-items-center items-center">
                          <div className="text-black flex justify-items-center items-center border-2 border-black rounded">
                            <PlusOutlined
                              style={{
                                fontSize: '11px',
                                padding: 1,
                                fontWeight: 'bolder',
                              }}
                            />
                          </div>
                          <span className="pl-2">{t('設定する')}</span>
                        </div>
                      </Form.Item>

                      <Form.Item
                        name="videoQualitySetting"
                        label={
                          <>
                            <Text className="text-xs font-bold">
                              {t('動画の画質設定')}
                            </Text>

                            <QuestionTooltip
                              TitleChild={
                                <span className="p-2">
                                  {t('動画の画質を選択することができます。')}
                                  <br />
                                  {t(
                                    'WiFiが弱い環境では低画質にすることで動画の読み込み時間が短縮されます。',
                                  )}
                                  <br />
                                  {t(
                                    '設定した画質が教職員・児童生徒のデフォルト画質になります。',
                                  )}
                                  <br />
                                </span>
                              }
                            />
                          </>
                        }
                      >
                        <Radio.Group className="w-full">
                          {/* show video demo for quality setting */}
                          <div className="relative h-48">
                            <div className="flex absolute -left-28">
                              <div className="flex flex-col">
                                <Radio key="input-on" value="1" checked={true}>
                                  {t('高画質')}
                                </Radio>
                                <div className="h-2" />

                                <div
                                  className="cursor-pointer w-72"
                                  onClick={(event) => {
                                    setSelectedVideo(lowHighQualityVideos[1])
                                    event.stopPropagation()
                                  }}
                                  onTouchEnd={(event) => {
                                    setSelectedVideo(lowHighQualityVideos[1])
                                    event.stopPropagation()
                                  }}
                                >
                                  <img
                                    src={lowHighQualityVideos[1].thumb}
                                    alt=""
                                  />
                                </div>
                              </div>

                              <div className="w-10" />

                              <div className="flex flex-col">
                                <Radio key="input-off" value="0">
                                  {t('低画質')}
                                </Radio>
                                <div className="h-2" />

                                <div
                                  className="cursor-pointer w-72"
                                  onClick={(event) => {
                                    setSelectedVideo(lowHighQualityVideos[0])
                                    event.stopPropagation()
                                  }}
                                  onTouchEnd={(event) => {
                                    setSelectedVideo(lowHighQualityVideos[0])
                                    event.stopPropagation()
                                  }}
                                >
                                  <img
                                    src={lowHighQualityVideos[0].thumb}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Radio.Group>
                      </Form.Item>

                      <div className="text-center">
                        <Form.Item>
                          <Button
                            className="w-60 mt-6 rounded"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                          >
                            {t('登録')}
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </>
          )}
        </Dashboard>
      ) : (
        <Dashboard selectedMenu={8} navbar={t('設定')}>
          <div />
        </Dashboard>
      )}

      {selectedVideo && (
        <SimpleVideoModal
          video={selectedVideo}
          onClose={() => setSelectedVideo(undefined)}
        />
      )}

      <Modal
        visible={showSavingPopup}
        footer={null}
        closable={false}
        className="mt-4"
      >
        <div
          className="justify-center flex"
          style={{
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '21px',
            margin: '-24px -24px 0',
            padding: '24px 0',
          }}
        >
          <span>
            {t('このページから移動しますか？')}
            <br />
            {t('変更内容を保存する場合は、必ず「登録」ボタンを押してください')}
            <br />
          </span>
        </div>
        <div className="mt-8 justify-center flex">
          <Button
            className="w-60 mr-2"
            size="large"
            onClick={() => {
              setShowSavingPopup(() => false)
              const goToUrl = nextUrl ?? ''
              if (goToUrl.startsWith('http')) {
                window.open(goToUrl, '_blank')
              } else {
                history.push(goToUrl)
              }
            }}
          >
            {t('保存せずに移動')}
          </Button>
          <Button
            type="primary"
            className="w-60 ml-2"
            size="large"
            onClick={() => {
              setShowSavingPopup(false)
            }}
          >
            {t('設定に戻る')}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default Setting
