import './QRCodeModal.css'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { LegacyRef, memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import type { MetaStudent, SchoolInvitationCode } from '~/alpha/core'
import { useAlphaStore } from '~/context'
import { chunkMutated } from '~/school-utils/cal'
import isJapanese from '~/utils/isJapanese'
import type { SchoolYearInfoTable } from '../pages/school-year-info/types'
import { PrintingFor1 } from './qr-code/PrintingFor1'
import { PrintingFor9 } from './qr-code/PrintingFor9'
import { QrList } from './qr-code/QrList'

export const QRCodeModal = memo(
  ({
    data,
    showModal,
    setShowModal,
  }: {
    data: {
      code: SchoolInvitationCode
      data: SchoolYearInfoTable[]
    }
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  }) => {
    const { t, i18n } = useTranslation()
    const { school } = useAlphaStore()
    const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
    const isUsingJp = isJapanese(i18n)
    const isUsingJpRuby = isElementarySchool && isUsingJp

    const studentArr = (data?.code.students || []) as MetaStudent[]

    const handleCancel = () => {
      setShowModal(false)
    }

    const nineModalRef = useRef<HTMLDivElement>()
    const oneModalRef = useRef<HTMLDivElement>()

    const handleNinePrint = useReactToPrint({
      content: () => nineModalRef.current ?? null,
    })

    const handleOnePrint = useReactToPrint({
      content: () => oneModalRef.current ?? null,
    })

    return (
      <>
        {data && (
          <Modal
            bodyStyle={{ background: '#E9ECF6', borderRadius: '10px' }}
            width={'1200px'}
            visible={showModal}
            onCancel={handleCancel}
            closable={false}
            closeIcon={<CloseOutlined className="text-base color-blue" />}
            footer={null}
          >
            <QrList
              students={studentArr}
              schoolGrade={data.code.schoolGrade}
              schoolClass={data.code.schoolClass}
              code={data.code.code}
              handleCancel={handleCancel}
            />

            <div className="w-full flex justify-center">
              <Button
                className="w-60 rounded mx-4"
                type="primary"
                onClick={handleNinePrint}
              >
                {t('印刷（1枚につき9人の情報）')}
              </Button>

              <Button
                className="w-60 rounded mx-4"
                type="primary"
                onClick={handleOnePrint}
              >
                {t('印刷（1枚につき1人の情報）')}
              </Button>
            </div>

            <div
              ref={nineModalRef as LegacyRef<HTMLDivElement>}
              className="print-container m-0 p-0"
            >
              {chunkMutated(chunkMutated(studentArr, 3), 3).map(
                (chunkOf3, id) => (
                  <div
                    key={id}
                    className="relative h-a4 flex flex-col justify-center items-center"
                  >
                    <div className="min-h-909px">
                      {chunkOf3.map((chunkOf3Of3, deeperId) => (
                        <PrintingFor9
                          key={deeperId}
                          students={chunkOf3Of3}
                          schoolGrade={data.code.schoolGrade}
                          schoolClass={data.code.schoolClass}
                          code={data.code.code}
                          isUsingJpRuby={isUsingJpRuby}
                        />
                      ))}
                    </div>

                    <div className="flex flex-col justify-center items-center text-black">
                      <span>
                        {t('児童生徒用ALPHAのサインイン画面にアクセスしてから')}
                      </span>
                      <span>{t('上記のQRコードを読み取ってください')}</span>
                      <span>
                        ※{t('詳細は「ALPHAご利用の手引き」をご確認ください')}
                      </span>
                    </div>
                  </div>
                ),
              )}
            </div>

            <div
              ref={oneModalRef as LegacyRef<HTMLDivElement>}
              className="print-container m-0 p-0"
            >
              {studentArr.map(
                (
                  {
                    attendanceNumber,
                    familyName,
                    givenName,
                    studentInvitationCode,
                  },
                  id,
                ) => (
                  <div key={id}>
                    <div className="page-break" />
                    <PrintingFor1
                      attendanceNumber={attendanceNumber}
                      familyName={familyName}
                      givenName={givenName}
                      studentInvitationCode={studentInvitationCode}
                      schoolGrade={data.code.schoolGrade}
                      schoolClass={data.code.schoolClass}
                      code={data.code.code}
                      isUsingJpRuby={isUsingJpRuby}
                    />
                  </div>
                ),
              )}
            </div>
          </Modal>
        )}
      </>
    )
  },
)
