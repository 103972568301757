import {
  fetchSchoolClasses,
  useGradesLazyQuery,
  useStudentsLazyQuery,
} from '@alpha/core'
import {
  Button,
  Col,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  message,
} from 'antd'
import _ from 'lodash'
import {
  LegacyRef,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { WorkBook, set_cptable, utils, writeFile } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'

import { useAlphaStore } from '~/context'
import { useLoadingPercent } from '~/hooks/useLoadingPercent'
import { readWorkbookFromPublic } from '~/school-utils/download'
import { getQuestionFilledEmptyCellsLength } from '~/school-utils/questions'
import { LastYearResults } from '~/types/api/alpha/old-test-result'
import { Gender } from '~/types/api/user'
import Api from '~/utils/api'
import { calculateBmi, calculateBmiByExistWeightHeight } from '~/utils/bmi'
import { currentYear, pageSize as defaultPageSize } from '~/utils/constants'
import { FitnessTest } from '~/utils/fitnessTest'
import isJapanese from '~/utils/isJapanese'
import { TTestResultWithPoint } from '~/utils/points'
import { getCurrentPrefectureQuestions } from '~/utils/questions'
import { allQuestions } from '~/utils/questions/all-questions'
import { showTestResultValue } from '~/utils/test-result'
import type { Question } from '~/utils/types'
import {
  ageFromSchool,
  checkIfIsValidRank,
  reCalcTestResult,
  schoolCategoryJpNameCode,
  schoolHasEnduranceTest,
} from '../../utils'
import {
  ICurrentYearStudent,
  TStudent,
  TTestRes,
} from '../../utils/types/api/res'
import { Dashboard } from '../layout/Dashboard'
import StudentTestResultModal from '../modal/StudentTestResultModal'
import CircularProgressLoading from '../shared/CircularProgressLoading'
import { incorrectValue } from '../shared/IncorrectValue'
import ScreenLoading from '../shared/loading/ScreenLoading'
import TestResultsPrintPage from './TestResultsPrintPage'
import AwardsPrinting from './test-result/components/AwardsPrinting'
import type {
  IJpSummaryXlsx,
  IJpXlsx,
  IStudentResultResponse,
  StudentTestResult,
} from './test-result/types/test-result'
import { isMobile } from 'react-device-detect'
import QuestionTooltip from '../cards/QuestionTooltip'

set_cptable(cp_table)

const { Option } = Select

const checkValue0 = (val: any, point: any) => {
  if (!(point > 0)) {
    return ''
  }

  if (val === 0 || val === '0') {
    return val
  }

  return val || ''
}

const maxQuestionId = 50

/**
 * 体力テスト一覧ページ
 *
 * Path: /test_result
 */
const TestResultPage = () => {
  const { school } = useAlphaStore()

  const schoolCategoryCode = school?.attributes?.schoolCategoryCode

  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  const [schoolGrade, setSchoolGrade] = useState(0)
  const [schoolClass, setSchoolClass] = useState(0)
  const [schoolClasses, setSchoolClasses] = useState<number[]>([])

  /**
   * For table view.
   */
  const [filteredStudents, setFilteredStudents] = useState<
    TStudent[] | undefined
  >()

  /**
   * For printing.
   */
  const [filteredResults, setFilteredResults] = useState<
    StudentTestResult[] | undefined
  >()

  /**
   * Recalculated data from fetched results only.
   * Used at "download" button.
   */
  const [reCalStudentsAllData, setReCalStudentsAllData] = useState<TStudent[]>(
    [],
  )

  const [total, setTotal] = useState(0)

  const [selectedStudent, setSelectedStudent] =
    useState<null | StudentTestResult>(null)

  const [showDetailModal, setShowDetailModal] = useState(false)

  const [filterGender, setFilterGender] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [detailLoading, setDetailLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filteredResultsLoading, setFilteredResultsLoading] = useState(false)

  const [questionData, setQuestionData] = useState<Question[]>([])

  const questionFilledEmptyCellsLength =
    getQuestionFilledEmptyCellsLength(questionData)

  const hasEnduranceRun = schoolHasEnduranceTest(school?.attributes)

  const studentTestForPrintRef = useRef<HTMLDivElement>()
  const awardForPrintRef = useRef<HTMLDivElement>()

  const EditableContext = createContext<FormInstance<any> | null>(null)

  const maleTxt = t('男')
  const femaleTxt = t('女')

  const [
    getGrades,
    { data: gradesData, loading: gradesDataLoading, error: gradesDataErr },
  ] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [getAllStudents, { data: allStudentsData, loading, error, called }] =
    useStudentsLazyQuery({
      fetchPolicy: 'network-only',
    })

  const currentYearStudents = filteredStudents?.filter((student) => {
    const { testResults } = student.attributes
    if (!testResults) {
      return false
    }

    return (
      testResults.findIndex(
        (testResult) => testResult.testYear === currentYear,
      ) !== -1
    )
  }) as ICurrentYearStudent[] | undefined

  const rankAStudents = currentYearStudents?.filter((student) => {
    return student.attributes.testResults.find(
      (testResult) =>
        testResult.rank === 'A' && testResult.testYear === currentYear,
    )
  })

  const isDataReadyToUsed =
    !gradesDataLoading && !gradesDataErr && !loading && !error && called

  // setGrade
  useEffect(() => {
    if (!gradesData?.grades?.data) return
    setSchoolGrade(
      gradesData.grades.data.length
        ? gradesData.grades.data[0]?.schoolGrade
        : 0,
    )
  }, [gradesData])

  // API callings
  useEffect(() => {
    if (!school) return

    const schoolId = school._id

    getAllStudents({
      variables: {
        input: {
          schoolId,
          testYear: currentYear,
        },
      },
    })

    getGrades({
      variables: {
        input: {
          schoolId,
          testYear: currentYear,
        },
      },
    })

    fetchSchoolClasses(schoolId, currentYear).then((res) => {
      const schoolClasses = res.schoolClasses

      if (!schoolClasses.length) {
        return
      }

      setSchoolClasses(schoolClasses)

      // default set to first class in class list.
      setSchoolClass(schoolClasses[0])
    })

    const isElementarySchool = schoolCategoryCode === 'B1'

    const questionList = getCurrentPrefectureQuestions(
      school.attributes?.prefectureCode,
      schoolId,
      isElementarySchool,
    )

    /**
     * To update this prefecture question list.
     */
    const prefectureQuestionObject: Record<string, Question> = {}

    for (const question of allQuestions) {
      if (questionList.includes(question.id)) {
        // question is modified later, so copy question information to new object
        prefectureQuestionObject[question.id] = _.cloneDeep(question)
      }
    }

    /**
     * To reorder questions
     */
    const prefectureQuestionDetails: Question[] = []

    for (const allQuestionId of questionList) {
      prefectureQuestionDetails.push(prefectureQuestionObject[allQuestionId])
    }

    setQuestionData(prefectureQuestionDetails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school])

  // For table data.
  // handle when API called completely.
  useEffect(() => {
    if (!allStudentsData?.students?.data || !schoolCategoryCode) return

    const clonedStudentsAllData = _.cloneDeep(allStudentsData.students.data)

    clonedStudentsAllData.forEach((student) => {
      if (!student?.attributes?.testResults) return

      const testResults = student.attributes.testResults

      const trIdx = testResults.findIndex((d) => d.testYear === currentYear)

      if (trIdx >= 0) {
        const age = ageFromSchool(
          schoolCategoryCode,
          student.attributes.schoolGrade as number,
        )

        const gender = student.attributes.gender as Gender | undefined

        if (gender) {
          const testRes = testResults[trIdx] as TTestRes

          // @ts-ignore
          student.attributes.testResults[trIdx] = reCalcTestResult(
            testRes,
            age,
            gender,
            hasEnduranceRun,
          )
        }
      }
    })

    // @ts-ignore
    setReCalStudentsAllData(clonedStudentsAllData)
  }, [allStudentsData, schoolCategoryCode, hasEnduranceRun])

  // handle when filter changed
  useEffect(() => {
    if (reCalStudentsAllData.length === 0) return

    let studentsAllData = (reCalStudentsAllData ?? []).filter(
      (student) =>
        (student.attributes.schoolClass === schoolClass || schoolClass === 0) &&
        (student.attributes.schoolGrade === schoolGrade || schoolGrade === 0),
    )

    if (filterGender !== '') {
      studentsAllData = studentsAllData.filter(
        (student) => student.attributes.gender === filterGender,
      )
    }

    setTotal(studentsAllData.length)
    setFilteredStudents(studentsAllData)
  }, [reCalStudentsAllData, schoolClass, schoolGrade, filterGender])

  /**
   * Download for all students, not filtered.
   */
  const downloadXlsx = async () => {
    if (!reCalStudentsAllData || !school || !schoolCategoryCode) {
      console.error(
        'school data is not fetched yet from API, skipping downloading...',
      )

      return
    }

    const schoolName = school.attributes.schoolName
    const schoolCategoryText = t(schoolCategoryJpNameCode[schoolCategoryCode])

    const dateOfBirthTitle = t('生年月日')
    const nothingText = t('無')

    const gradeKey = t('学年')
    const classKey = t('組')
    const studentOrderKey = t('出席番号')

    const scoreKey = t('得点')

    setIsLoading(true)

    const readWorkbook: WorkBook | undefined = await readWorkbookFromPublic(
      t,
      'res',
    )
    if (readWorkbook === undefined) {
      return
    }

    let firstSheetXlsxSummaryData: (
      | IJpXlsx
      | Record<string, number | string>
      | any
    )[] = reCalStudentsAllData.map((student) => {
      const attributes = student.attributes

      const testResult = attributes.testResults?.find(
        (d) => d.testYear === currentYear,
      )

      const showRanking = checkIfIsValidRank(
        testResult as TTestResultWithPoint | undefined,
        hasEnduranceRun,
      )

      const firstSheetXlsxSummaryDataItem = {
        [t('学校名')]: schoolName,
        [t('学校種')]: schoolCategoryText,
        [t('年度')]: currentYear,
        [gradeKey]: attributes.schoolGrade,
        [classKey]: attributes.schoolClass,
        [studentOrderKey]: attributes.schoolAttendanceNumber ?? '',
        [t('姓名')]: t('フルネーム', {
          familyName: attributes.familyName,
          givenName: attributes.givenName,
        }),
        [t('性別')]: attributes.gender === 'MALE' ? maleTxt : femaleTxt,
        [t('総得点')]: testResult?.points,
        [t('評価')]: showRanking ? testResult?.rank : '',
        [t('身長')]: testResult?.sizeTest?.height,
        [t('体重')]: testResult?.sizeTest?.weight,

        [t('握力右1')]: testResult?.grip?.isNotMeasurable
          ? nothingText
          : testResult?.grip?.gripRight1 ?? '',
        [t('握力右2')]: testResult?.grip?.isNotMeasurable
          ? nothingText
          : testResult?.grip?.gripRight2 ?? '',
        [t('握力左1')]: testResult?.grip?.isNotMeasurable
          ? nothingText
          : testResult?.grip?.gripLeft1 ?? '',
        [t('握力左2')]: testResult?.grip?.isNotMeasurable
          ? nothingText
          : testResult?.grip?.gripLeft2 ?? '',
        [t('上体起こし')]: testResult?.sitUps?.isNotMeasurable
          ? nothingText
          : (testResult?.sitUps?.points as number) > 0
          ? testResult?.sitUps?.value ?? ''
          : '',
        [t('長座体前屈1')]: testResult?.bending?.isNotMeasurable
          ? nothingText
          : testResult?.bending?.bending1 ?? '',
        [t('長座体前屈2')]: testResult?.bending?.isNotMeasurable
          ? nothingText
          : testResult?.bending?.bending2 ?? '',
        [t('反復横跳び1')]: testResult?.sideJump?.isNotMeasurable
          ? nothingText
          : testResult?.sideJump?.sideJump1 ?? '',
        [t('反復横跳び2')]: testResult?.sideJump?.isNotMeasurable
          ? nothingText
          : testResult?.sideJump?.sideJump2 ?? '',
        [t('20mシャトルラン')]: testResult?.shuttleRun?.isNotMeasurable
          ? nothingText
          : testResult?.shuttleRun?.shuttleRunCount ?? '',
        [t('50m走')]: testResult?.sprintRun?.isNotMeasurable
          ? nothingText
          : testResult?.sprintRun?.value
          ? testResult?.sprintRun?.value
          : '',
        [t('立ち幅跳び1')]: testResult?.standingJump?.isNotMeasurable
          ? nothingText
          : testResult?.standingJump?.standingJump1 ?? '',
        [t('立ち幅跳び2')]: testResult?.standingJump?.isNotMeasurable
          ? nothingText
          : testResult?.standingJump?.standingJump2 ?? '',
        [t('ボール投げ1')]: testResult?.handballThrow?.isNotMeasurable
          ? nothingText
          : testResult?.handballThrow?.handballThrow1 ?? '',
        [t('ボール投げ2')]: testResult?.handballThrow?.isNotMeasurable
          ? nothingText
          : testResult?.handballThrow?.handballThrow2 ?? '',
        [t('持久走（分）')]: !hasEnduranceRun
          ? ''
          : testResult?.enduranceRun?.isNotMeasurable
          ? nothingText
          : testResult?.enduranceRun?.runningTime ?? '',
        [t('持久走（秒）')]: !hasEnduranceRun
          ? ''
          : testResult?.enduranceRun?.isNotMeasurable
          ? nothingText
          : testResult?.enduranceRun?.runningTimeSeconds ?? '',
        [t('年')]: attributes?.yearBirth || '',
        [t('月')]: attributes?.monthBirth || '',
        [t('日')]: attributes?.dayBirth || '',
      }

      // question data
      for (let i = 1; i <= maxQuestionId; i++) {
        const qId = `q${i}`

        firstSheetXlsxSummaryDataItem[qId] =
          testResult?.questionnaireV3?.[qId] ?? ''
      }

      return firstSheetXlsxSummaryDataItem
    })

    let secondSheetXlsxFinalData: (
      | IJpSummaryXlsx
      | Record<string, number | string>
    )[] = reCalStudentsAllData.map((student) => {
      const attributes = student.attributes

      const testResult = attributes.testResults?.find(
        (d) => d.testYear === currentYear,
      )

      const showRanking = checkIfIsValidRank(
        testResult as TTestResultWithPoint,
        hasEnduranceRun,
      )

      const recordKey = t('記録')

      const secondSheetXlsxSummaryRecord = {
        [t('学校名')]: schoolName,
        [t('学校種')]: schoolCategoryText,
        [t('年度')]: currentYear,
        [gradeKey]: attributes.schoolGrade,
        [classKey]: attributes.schoolClass,
        [studentOrderKey]: attributes.schoolAttendanceNumber ?? '',
        [t('姓名')]: t('フルネーム', {
          familyName: attributes.familyName,
          givenName: attributes.givenName,
        }),
        [t('性別')]: attributes.gender === 'MALE' ? maleTxt : femaleTxt,

        [t('身長')]: testResult?.sizeTest?.height,
        [t('体重')]: testResult?.sizeTest?.weight,

        [`${t('握力')}_${recordKey}`]: testResult?.grip?.isNotMeasurable
          ? nothingText
          : checkValue0(testResult?.grip?.value, testResult?.grip?.points),
        [`${t('上体起こし')}_${recordKey}`]: testResult?.sitUps?.isNotMeasurable
          ? nothingText
          : (testResult?.sitUps?.points as number) > 0
          ? testResult?.sitUps?.value ?? ''
          : '',
        [`${t('長座体前屈')}_${recordKey}`]: testResult?.bending
          ?.isNotMeasurable
          ? nothingText
          : testResult?.bending?.value || '',
        [`${t('反復横跳び')}_${recordKey}`]: testResult?.sideJump
          ?.isNotMeasurable
          ? nothingText
          : testResult?.sideJump?.value || '',
        [`${t('20mシャトルラン')}_${recordKey}`]: testResult?.shuttleRun
          ?.isNotMeasurable
          ? nothingText
          : testResult?.shuttleRun?.value || '',
        [`${t('50m走')}_${recordKey}`]: testResult?.sprintRun?.isNotMeasurable
          ? nothingText
          : testResult?.sprintRun?.value
          ? testResult?.sprintRun?.value
          : '',
        [`${t('立ち幅跳び')}_${recordKey}`]: testResult?.standingJump
          ?.isNotMeasurable
          ? nothingText
          : testResult?.standingJump?.value || '',
        [`${t('ボール投げ')}_${recordKey}`]: testResult?.handballThrow
          ?.isNotMeasurable
          ? nothingText
          : checkValue0(
              testResult?.handballThrow?.value,
              testResult?.handballThrow?.points,
            ),
        [`${t('持久走（分）')}_${recordKey}`]: !hasEnduranceRun
          ? ''
          : testResult?.enduranceRun?.isNotMeasurable
          ? nothingText
          : testResult?.enduranceRun?.runningTime ?? '',
        [`${t('持久走（秒）')}_${recordKey}`]: !hasEnduranceRun
          ? ''
          : testResult?.enduranceRun?.isNotMeasurable
          ? nothingText
          : testResult?.enduranceRun?.runningTimeSeconds ?? '',

        [t('生年月日（年）')]: attributes.yearBirth || '',
        [t('生年月日（月）')]: attributes.monthBirth || '',
        [t('生年月日（日）')]: attributes.dayBirth || '',

        [`${t('握力')}_${scoreKey}`]: showTestResultValue(testResult?.grip),
        [`${t('上体起こし')}_${scoreKey}`]: showTestResultValue(
          testResult?.sitUps,
        ),
        [`${t('長座体前屈')}_${scoreKey}`]: showTestResultValue(
          testResult?.bending,
        ),
        [`${t('反復横跳び')}_${scoreKey}`]: showTestResultValue(
          testResult?.sideJump,
        ),
        [`${t('20mシャトルラン')}_${scoreKey}`]: showTestResultValue(
          testResult?.shuttleRun,
        ),
        [`${t('50m走')}_${scoreKey}`]: showTestResultValue(
          testResult?.sprintRun,
        ),
        [`${t('立ち幅跳び')}_${scoreKey}`]: showTestResultValue(
          testResult?.standingJump,
        ),
        [`${t('ボール投げ')}_${scoreKey}`]: showTestResultValue(
          testResult?.handballThrow,
        ),
        [`${t('持久走')}_${scoreKey}`]: showTestResultValue(
          testResult?.enduranceRun,
        ),

        [`${t('総得点')}_${scoreKey}`]: testResult?.points || '',
        [`${t('評価')}_${scoreKey}`]: showRanking ? testResult?.rank : '',
      }

      // question data
      for (let i = 1; i <= maxQuestionId; i++) {
        const qId = `q${i}`

        secondSheetXlsxSummaryRecord[qId] =
          testResult?.questionnaireV3?.[qId] ?? ''
      }

      secondSheetXlsxSummaryRecord[t('視力（右）\n裸視')] = ''
      secondSheetXlsxSummaryRecord[t('視力（左）\n裸視')] = ''
      secondSheetXlsxSummaryRecord[t('視力（右）\n矯正')] = ''
      secondSheetXlsxSummaryRecord[t('視力（左）\n矯正')] = ''

      // last question's answer, usually that is the ID `45` question.
      const lastQuestionOrderId = questionData.length
      secondSheetXlsxSummaryRecord[t('部活動\nコード')] =
        testResult?.questionnaireV3?.[`q${lastQuestionOrderId}`] ?? ''

      return secondSheetXlsxSummaryRecord
    })

    function compare(
      a: IJpXlsx | IJpSummaryXlsx | Record<string, string | number>,
      b: IJpXlsx | IJpSummaryXlsx | Record<string, string | number>,
    ) {
      if (a[gradeKey] !== b[gradeKey]) return a[gradeKey] - b[gradeKey]

      if (a[classKey] !== b[classKey]) return a[classKey] - b[classKey]

      return a[studentOrderKey] - b[studentOrderKey]
    }

    firstSheetXlsxSummaryData = firstSheetXlsxSummaryData.sort(compare)
    secondSheetXlsxFinalData = secondSheetXlsxFinalData.sort(compare)

    const newWorkbook = utils.book_new()
    const firstWorksheet = utils.json_to_sheet([])

    utils.sheet_add_aoa(firstWorksheet, [
      [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'cm',
        'kg',
        'kg',
        'kg',
        'kg',
        'kg',
        t('回'),
        'cm',
        'cm',
        t('点'),
        t('点'),
        t('回'),
        t('秒'),
        'cm',
        'cm',
        'm',
        'm',
        t('分'),
        t('秒'),
        dateOfBirthTitle,
        dateOfBirthTitle,
        dateOfBirthTitle,
      ],
    ])

    utils.sheet_add_json(firstWorksheet, firstSheetXlsxSummaryData, {
      origin: 'A2',
    })

    const secondWorksheetSummary = utils.json_to_sheet(secondSheetXlsxFinalData)

    utils.book_append_sheet(
      newWorkbook,
      firstWorksheet,
      t('測定結果（全記録）'),
    )

    utils.book_append_sheet(
      newWorkbook,
      secondWorksheetSummary,
      t('測定結果（最終結果）'),
    )

    // "測定結果（関数）" sheet
    utils.book_append_sheet(
      newWorkbook,
      readWorkbook.Sheets['測定結果（関数）'],
      t('測定結果（関数）'),
    )

    if (newWorkbook.Sheets['測定結果（関数）']) {
      utils.book_set_sheet_visibility(newWorkbook, 2, 1)
    }

    writeFile(newWorkbook, `test_result_${schoolName}.xlsx`, {
      bookType: 'xlsx',
    })

    setIsLoading(false)
  }

  const renderTest = (v: any[] | undefined | null, test: string) => {
    const defVal = incorrectValue
    if (!v || v.length === 0) return defVal
    const tr = v.find((d) => d.testYear === currentYear)
    if (!tr || tr.length === 0) return defVal

    const nothingText = t('無')

    let value: string | number | ReactElement | undefined
    const pointObj = tr[test]

    switch (test) {
      case 'bending':
      case 'standingJump':
      case 'handballThrow': {
        if (pointObj && pointObj.points > 0 && !pointObj.isNotMeasurable) {
          value =
            pointObj.isNotMeasurable === true ? nothingText : pointObj.value
        }
        // if (!tr[test] || tr[test].length === 0) return defVal
        // value = tr[test].isNotMeasurable === true ? nothingText : tr[test].value
        // if (value === null || value === undefined || (!value && test !== 'sitUps')) return defVal
        break
      }
      case 'grip':
        if (pointObj && pointObj.points > 0 && !pointObj.isNotMeasurable) {
          value =
            pointObj.isNotMeasurable === true ? nothingText : pointObj.value
        }
        break
      case 'sitUps': {
        if (pointObj && pointObj.points > 0 && !pointObj.isNotMeasurable) {
          value =
            pointObj.isNotMeasurable === true ? nothingText : pointObj.value
        }

        break
      }
      case 'sprintRun':
      case 'sideJump':
      case 'shuttleRun': {
        if (pointObj && pointObj.points > 0 && !pointObj.isNotMeasurable) {
          value =
            pointObj.isNotMeasurable === true ? nothingText : pointObj.value
        }
        // if (!tr[test] || tr[test].length === 0) return defVal
        // value = tr[test].isNotMeasurable === true ? nothingText : tr[test].value
        // if (value === null || value === undefined || (!value && test !== 'sitUps')) return defVal
        break
      }

      case 'enduranceRun': {
        if (!pointObj || pointObj.length === 0) return defVal
        if (tr.enduranceRun?.isNotMeasurable === true) return nothingText

        value =
          (pointObj.runningTime !== null &&
            !Number.isNaN(Number.parseInt(pointObj.runningTime))) ||
          (pointObj.runningTimeSeconds !== null &&
            !Number.isNaN(Number.parseInt(pointObj.runningTimeSeconds)))
            ? `${
                tr.enduranceRun?.runningTime !== null &&
                !Number.isNaN(Number.parseInt(tr.enduranceRun?.runningTime))
                  ? `${Number.parseInt(tr.enduranceRun?.runningTime)}`
                  : '0'
              }'${tr.enduranceRun?.runningTimeSeconds || 0}''`
            : defVal

        if (!value) return defVal
        break
      }

      case 'height':
      case 'weight': {
        value = tr.sizeTest?.[test]
        if (!value || (value as string).length === 0) return defVal
        break
      }

      case 'bmi': {
        if (tr.sizeTest?.weight && tr.sizeTest.height) {
          value = calculateBmiByExistWeightHeight(tr.sizeTest)

          if (!value) return defVal
        } else {
          return defVal
        }
        break
      }
      case 'points':
      case 'rank': {
        value = pointObj
        if (!value || (value as string).length === 0) return defVal
        break
      }
    }

    return (
      <div className={pointObj?.memo ? 'underline cursor-pointer' : ''}>
        <Tooltip placement="top" title={pointObj?.memo}>
          <div>{value}</div>
        </Tooltip>
      </div>
    )
  }

  const getTestResults = (student) => {
    return student.attributes.testResults?.find(
      (item) => item.testYear === currentYear,
    )
  }

  const sortTestResults = (a, b, key) => {
    let element1: undefined | number | string
    let element2: undefined | number | string

    switch (key) {
      case 'grip':
      case 'sitUps':
      case 'bending':
      case 'sprintRun':
      case 'sideJump':
      case 'standingJump':
      case 'shuttleRun':
      case 'handballThrow':
      case 'enduranceRun': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = a_tr?.[key]?.value
        element2 = b_tr?.[key]?.value
        break
      }
      case 'rank':
      case 'points': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = a_tr?.[key]
        element2 = b_tr?.[key]
        break
      }
      case 'height':
      case 'weight': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = a_tr?.sizeTest?.[key]
        element2 = b_tr?.sizeTest?.[key]
        break
      }
      case 'name': {
        element1 = `${a?.familyName ?? ''}${a?.givenName ?? ''}`
        element2 = `${b?.familyName ?? ''}${b?.givenName ?? ''}`
        break
      }
      case 'bmi': {
        const a_tr = getTestResults(a)
        const b_tr = getTestResults(b)

        element1 = calculateBmi(a_tr?.sizeTest)
        element2 = calculateBmi(b_tr?.sizeTest)
        break
      }
      default: {
        element1 = a?.[key]
        element2 = b?.[key]
      }
    }

    if (element1 == null && element2 == null) return 1
    if (element1 == null) return -1
    if (element2 == null) return 1

    return typeof element1 === 'number'
      ? element1 - (element2 as number)
      : element1.localeCompare(element2 as string)
  }

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const columns: any[] = [
    {
      title: t('学年'),
      key: 'schoolGrade',
      dataIndex: ['attributes', 'schoolGrade'],
      className: 'text-center-f whitespace-nowrap',
      width: isUsingJp ? 60 : 72,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolGrade')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('組'),
      dataIndex: ['attributes', 'schoolClass'],
      key: 'schoolClass',
      className: 'text-center-f whitespace-nowrap',
      width: isUsingJp ? 42 : 68,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolClass')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('番'),
      key: 'schoolAttendanceNumber',
      dataIndex: ['attributes', 'schoolAttendanceNumber'],
      className: 'text-center-f whitespace-nowrap',
      width: isUsingJp ? 42 : 88,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolAttendanceNumber')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('性別'),
      dataIndex: ['attributes', 'gender'],
      key: 'gender',
      className: 'text-center-f whitespace-nowrap',
      width: 60,
      sorter: (a, b) => {
        const gender1 = a?.attributes?.gender
        const gender2 = b?.attributes?.gender

        if (!gender1 && !gender2) return 1
        if (!gender1) return -1
        if (!gender2) return 1
        return gender1.localeCompare(gender2)
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => (
        <div>{v ? (v === 'MALE' ? maleTxt : femaleTxt) : '-'}</div>
      ),
    },
    {
      title: t('名前'),
      key: 'name',
      dataIndex: ['attributes'],
      className: 'text-center-f whitespace-nowrap',
      width: 102,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'name')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => (
        <div className="whitespace-nowrap">
          {t('フルネーム', {
            familyName: v.familyName,
            givenName: v.givenName,
          })}
        </div>
      ),
    },
    {
      title: t('総得点'),
      key: 'points',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap bg-white',
      width: isUsingJp ? 78 : 120,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'points')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        const tr = v.find((d) => d.testYear === currentYear)
        return <div>{tr ? tr?.points : incorrectValue}</div>
      },
    },
    {
      title: t('評価'),
      key: 'rank',
      width: isUsingJp ? 60 : 108,
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap bg-white',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'rank')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v: TTestRes[]) => {
        if (!v || v.length === 0) return incorrectValue

        const tr = v.find((d) => d.testYear === currentYear)
        const showRanking = checkIfIsValidRank(
          tr as TTestResultWithPoint,
          hasEnduranceRun,
        )

        let rank = tr?.rank

        if (!tr?.rank && showRanking) {
          const resObj = v as TTestRes
          rank = FitnessTest.computeRank(
            resObj.points || undefined,
            Number.isNaN(resObj.age) ? 6 : (resObj.age as number),
          )
        }

        return <div>{showRanking ? rank : incorrectValue}</div>
      },
    },

    {
      title: t('身長'),
      dataIndex: ['attributes', 'testResults'],
      key: 'height',
      className: 'text-center-f whitespace-nowrap bg-white',
      width: isUsingJp ? 60 : 76,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'height')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'height')
      },
    },
    {
      title: t('体重'),
      dataIndex: ['attributes', 'testResults'],
      key: 'weight',
      className: 'text-center-f whitespace-nowrap bg-white',
      width: isUsingJp ? 60 : 78,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'weight')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'weight')
      },
    },
    {
      title: 'BMI',
      dataIndex: ['attributes', 'testResults'],
      key: 'bmi',
      className: 'text-center-f whitespace-nowrap bg-white',
      width: isUsingJp ? 68 : 80,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'bmi')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'bmi')
      },
    },
    {
      title: t('握力'),
      key: 'grip',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap bg-white',
      width: isUsingJp ? 60 : 128,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'grip')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'grip')
      },
    },
    {
      title: t('上体起こし'),
      key: 'sitUps',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap bg-white',
      width: 102,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sitUps')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'sitUps')
      },
    },
    {
      title: t('長座体前屈'),
      key: 'bending',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap bg-white',
      width: isUsingJp ? 102 : 128,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'bending')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'bending')
      },
    },
    {
      title: t('反復横跳び'),
      key: 'sideJump',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap bg-white',
      width: 102,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sideJump')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'sideJump')
      },
    },
    {
      title: t('シャトルラン'),
      key: 'shuttleRun',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap bg-white',
      width: isUsingJp ? 102 : 128,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'shuttleRun')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'shuttleRun')
      },
    },
    {
      title: t('50m走'),
      key: 'sprintRun',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap bg-white',
      width: 78,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sprintRun')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'sprintRun')
      },
    },
    {
      title: t('立ち幅跳び'),
      key: 'standingJump',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap bg-white',
      width: isUsingJp ? 102 : 188,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'standingJump')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'standingJump')
      },
    },
    {
      title: t('ボール投げ'),
      key: 'handballThrow',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap bg-white',
      width: isUsingJp ? 102 : 128,
      sorter: (a, b) => {
        return sortTestResults(a, b, 'handballThrow')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'handballThrow')
      },
    },
    ...(hasEnduranceRun
      ? [
          {
            title: t('持久走'),
            key: 'enduranceRun',
            dataIndex: ['attributes', 'testResults'],
            className: 'text-center-f whitespace-nowrap bg-white',
            width: isUsingJp ? 102 : 128,
            sorter: (a, b) => {
              return sortTestResults(a, b, 'enduranceRun')
            },
            sortDirections: ['descend', 'ascend'],
            render: (v) => {
              return renderTest(v, 'enduranceRun')
            },
          },
        ]
      : []),
    {
      title: '',
      dataIndex: ['attributes'],
      className: 'text-center-f bg-white',
      width: isUsingJp ? 56 : 88,
      render: (v, student: TStudent) => (
        <div
          className={
            !v.testResults || v.testResults.length === 0
              ? 'bg-gray-160 -m-2'
              : ''
          }
        >
          <Button
            type="primary"
            className="ant-btn-xs"
            onClick={() => {
              handleOnGetStudentDetail(student)
            }}
          >
            {t('詳細')}
          </Button>
        </div>
      ),
    },
  ]

  const handleSave = (newRecord) => {
    const newData = [...(filteredStudents as TStudent[])]
    const index = newData.findIndex((item) => newRecord._id === item._id)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...newRecord })
    setFilteredStudents(newData)
    // TODO: Api save test result
  }

  const handleOnGetStudentDetail = async (student: TStudent) => {
    const apiUrl = `/alpha/v1/school/student/${student._id}/result`

    try {
      setDetailLoading(true)
      const { data } = await Api.get<IStudentResultResponse>(apiUrl)
      ;(student as StudentTestResult).lastYearResults = data.lastYearResults

      setSelectedStudent(student as StudentTestResult)
      setShowDetailModal(true)
    } catch (err) {
      message.error(
        `${t('エラーが発生しました。')} [${JSON.stringify(
          (err as Error).message,
        )}]`,
      )

      console.error(`when call ${apiUrl} - error:`, err)
    } finally {
      setDetailLoading(false)
    }
  }

  const handlePrintForIndividualResult = useReactToPrint({
    content: () => studentTestForPrintRef.current ?? null,
    documentTitle: `ALPHA${t('space個人結果表')}`,
  })

  const handleDataOnPrintForIndividualResult = async (): Promise<void> => {
    if (!currentYearStudents) {
      setFilteredResults(undefined)
      return handlePrintForIndividualResult()
    }

    const ids: string[] = currentYearStudents.map((student) => student._id)
    setFilteredResultsLoading(true)

    const apiUrl = '/alpha/v1/school/students/results'

    try {
      const { data } = await Api.post<
        { id: string; lastYearResults: LastYearResults }[]
      >(apiUrl, { ids })

      const studentsWithResults: StudentTestResult[] = currentYearStudents.map(
        // @ts-ignore
        (student: StudentTestResult) => {
          const lastYearResults = data.find(
            (item) => item.id === student._id,
          )?.lastYearResults

          student.lastYearResults = lastYearResults
          return student
        },
      )

      setFilteredResults(
        studentsWithResults.filter((student: TStudent) => {
          const testResults = student.attributes.testResults
          if (!testResults?.length) return false
          const {
            grip,
            sitUps,
            bending,
            sideJump,
            shuttleRun,
            sprintRun,
            standingJump,
            handballThrow,
            enduranceRun,
            sizeTest,
            questionnaireV3,
          } = testResults[0]
          return (
            grip?.value ||
            sitUps?.value ||
            bending?.value ||
            sideJump?.value ||
            shuttleRun?.value ||
            sprintRun?.value ||
            standingJump?.value ||
            handballThrow?.value ||
            sizeTest?.height ||
            sizeTest?.weight ||
            (hasEnduranceRun && enduranceRun?.value) ||
            questionnaireV3 !== null
          )
        }),
      )
    } catch (err) {
      message.error(
        `${t('エラーが発生しました。')} [${JSON.stringify(
          (err as Error).message,
        )}]`,
      )

      console.error(`when call ${apiUrl}`, err)
    } finally {
      setFilteredResultsLoading(false)
    }

    return handlePrintForIndividualResult()
  }

  const handlePrintForAward = useReactToPrint({
    content: () => awardForPrintRef.current ?? null,
    documentTitle: `ALPHA${t('体力賞')}`,
  })

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    )
  }

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const form = useContext(EditableContext)

    const tr = record?.attributes?.testResults?.find(
      (item) => item.testYear === currentYear,
    )

    useEffect(() => {
      if (editing && inputRef.current) {
        inputRef.current.focus()
      }
    }, [editing])

    const toggleEdit = () => {
      if (!form) {
        console.error('Form not found')
        return
      }

      if (!tr[dataIndex]?.isNotMeasurable) {
        setEditing(!editing)
        form.setFieldsValue({
          [dataIndex]: tr[dataIndex]?.value,
        })
      }
    }

    const save = async () => {
      if (!form) {
        console.error('Form not found')
        return
      }

      try {
        const values = await form.validateFields()
        toggleEdit()
        const newRecord = _.cloneDeep(record)

        const _tr = newRecord.attributes.testResults.find(
          (d) => d.testYear === currentYear,
        )

        _tr[dataIndex].value = Number.parseInt(values[dataIndex])
        handleSave(newRecord)
      } catch (err) {
        console.error('Save failed:', err)
      }
    }

    let childNode = children

    if (editable) {
      childNode = editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: t('{{title}}を入力してください', { title }),
            },
          ]}
        >
          <InputNumber
            size="small"
            step="any"
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      )
    }

    return <td {...restProps}>{childNode}</td>
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const editableColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    }
  })

  const loadingPercent = useLoadingPercent()

  return (
    <>
      <div className="print:hidden">
        <Dashboard selectedMenu={4} navbar={t('体力テスト一覧')}>
          <Row className="w-full justify-center">
            <Col className="mt-16" lg={{ span: 22 }}>
              <div className="space-x-2 pb-8">
                <Space size="small" className="float-right pr-28">
                  <QuestionTooltip
                    TitleChild={
                      <span>
                        {t('出生月ごとに平均値を算出したデータです。')}
                      </span>
                    }
                    children={<span className="font-semibold text-blue-500">
                      {t('全国月齢平均とは')}
                    </span>}
                    placement="topRight"
                    innerOverlayCss={{
                      width: 305,
                      padding: 20
                    }}
                  />
                  
                </Space>
              </div>
              <div className="space-x-2 pb-8">
                <Space size="middle">
                  <Select
                    className={`rounded-5px ${isUsingJp ? 'w-30' : 'w-36'}`}
                    value={schoolGrade}
                    onChange={setSchoolGrade}
                    dropdownAlign={{
                      offset: [0, -2],
                    }}
                  >
                    <Option key={'grade-default'} value={0}>
                      {t('全学年')}
                    </Option>
                    {gradesData?.grades?.data?.map((v) => (
                      <Option value={v.schoolGrade} key={v.schoolGrade}>
                        {t('個別学年', {
                          count: v.schoolGrade,
                          ordinal: !isUsingJp,
                        })}
                      </Option>
                    ))}
                  </Select>

                  <Select
                    className={`rounded-5px ${isUsingJp ? 'w-30' : 'w-36'}`}
                    value={schoolClass}
                    onChange={setSchoolClass}
                    dropdownAlign={{
                      offset: [0, -2],
                    }}
                  >
                    <Option key={'class-default'} value={0}>
                      {t('全組')}
                    </Option>

                    {schoolClasses.map((_class) => {
                      return (
                        <Option key={`class-${_class}`} value={_class}>
                          {t('個別組', {
                            count: _class,
                            ordinal: !isUsingJp,
                          })}
                        </Option>
                      )
                    })}
                  </Select>

                  <Select
                    className={`rounded-5px ${isUsingJp ? 'w-30' : 'w-36'}`}
                    value={filterGender}
                    onChange={(g) => setFilterGender(g)}
                    dropdownAlign={{
                      offset: [0, -2],
                    }}
                  >
                    <Option key="0" value="">
                      {t('全性別')}
                    </Option>
                    <Option key="1" value="MALE">
                      {t('男性')}
                    </Option>
                    <Option key="2" value="FEMALE">
                      {t('女性')}
                    </Option>
                  </Select>
                </Space>

                <Space size="middle" className="float-right">
                  <Button
                    type="primary"
                    className={`${isUsingJp ? 'w-34' : 'w-36'}`}
                    disabled={!isDataReadyToUsed}
                    onClick={downloadXlsx}
                  >
                    {t('ダウンロード')}
                  </Button>

                  <Button
                    type="primary"
                    className={`${isUsingJp ? 'w-34' : 'w-72'}`}
                    disabled={!isDataReadyToUsed}
                    onClick={handleDataOnPrintForIndividualResult}
                  >
                    {t('個人結果表印刷')}
                  </Button>

                  <Button
                    type="primary"
                    className={`${isUsingJp ? 'w-34' : 'w-64'}`}
                    disabled={!isDataReadyToUsed}
                    onClick={() => {
                      if (!rankAStudents?.length || !school) {
                        return message.warn(t('A評価の児童生徒がいません。'), 6)
                      }

                      return handlePrintForAward()
                    }}
                  >
                    {t('体力賞印刷')}
                  </Button>
                </Space>
              </div>

              <Table
                components={components}
                columns={editableColumns}
                dataSource={filteredStudents}
                loading={{
                  spinning:
                    loading ||
                    detailLoading ||
                    gradesDataLoading ||
                    filteredResultsLoading,
                  indicator: (
                    <CircularProgressLoading percent={loadingPercent} />
                  ),
                }}
                rowKey="_id"
                size="small"
                className="relative"
                style={{ minWidth: 940 }}
                scroll={{ y: 624 }}
                pagination={{
                  pageSize,
                  defaultPageSize: 50,
                  pageSizeOptions: [10, 20, 50, 100, 1000, 2000],
                  position: ['bottomCenter'],
                  showSizeChanger: true,
                  total,
                  current: currentPage,
                  onChange: (page: number, pageSize: number) => {
                    setCurrentPage(page)
                    setPageSize(pageSize)
                  },
                }}
                bordered={true}
              />

              {selectedStudent && school && (
                <StudentTestResultModal
                  testYear={currentYear}
                  student={selectedStudent}
                  isShow={showDetailModal}
                  setIsShow={setShowDetailModal}
                  school={school}
                  questionData={questionData}
                  hasEnduranceRun={hasEnduranceRun}
                  questionFilledEmptyCellsLength={
                    questionFilledEmptyCellsLength
                  }
                />
              )}
            </Col>
          </Row>
        </Dashboard>
      </div>

      {isDataReadyToUsed && school && (
        <>
          <div
            ref={studentTestForPrintRef as LegacyRef<HTMLDivElement>}
            className="print-component"
          >
            <TestResultsPrintPage
              testYear={currentYear}
              schoolClass={schoolClass}
              questionData={questionData}
              data={filteredResults}
              school={school}
              hasEnduranceRun={hasEnduranceRun}
              questionFilledEmptyCellsLength={questionFilledEmptyCellsLength}
            />
          </div>

          <div
            ref={awardForPrintRef as LegacyRef<HTMLDivElement>}
            className="print-component"
          >
            <AwardsPrinting
              testYear={currentYear}
              schoolClass={schoolClass}
              data={rankAStudents as TStudent[] | undefined}
              school={school}
              hasEnduranceRun={hasEnduranceRun}
              isMobile={isMobile}
            />
          </div>
        </>
      )}

      <ScreenLoading isLoading={isLoading} />
    </>
  )
}

export default TestResultPage
