import { FC } from 'react'
import { useVideojs } from 'react-videojs-hook'

import 'video.js/dist/video-js.css'
import ja from './videojs/languages/ja.json'

type Props = {
  src: string
  thumb: string
  size?: {
    height: number
    width: number
  }
}

const SimpleAutoPlayVideo: FC<Props> = ({
  src,
  size = { width: 292, height: 164 },
  thumb,
}) => {
  const { vjsId, vjsRef, vjsClassName } = useVideojs({
    src,
    poster: thumb,
    bigPlayButton: true,
    bigPlayButtonCentered: true,
    language: 'ja',
    languages: { ja },
    controls: true,
    autoplay: true,
    responsive: true,
    onReady: async () => {
      const ele = vjsRef.current as (Element & { play: () => void }) | null

      if (ele) {
        ele.play()
      }
    },
  })

  return (
    <div data-vjs-player>
      <video
        muted={false}
        ref={vjsRef}
        id={vjsId}
        preload="none"
        className={vjsClassName}
        width={size.width}
        height={size.height}
      />
    </div>
  )
}

export default SimpleAutoPlayVideo
