import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { fetchSchoolClasses } from '@alpha/core'
import { currentYear, pageSize as defaultPageSize } from '~/utils/constants'

import { useAlphaStore } from '~/context'
import {
  useSchoolGradesApiQuery,
  useSchoolSurveysApiQuery,
} from '~/hooks/hooks'
import { Surveys } from '~/types/api/survey'
import isJapanese from '~/utils/isJapanese'
import { getCurrentPrefectureQuestions } from '~/utils/questions'
import { allQuestions, getExtraQuestion } from '~/utils/questions/all-questions'
import {
  Question,
  StudentQuestionExtra,
} from '~/utils/types'
import { Dashboard } from '../layout/Dashboard'
import TableQuestion from '../layout/TableQuestion'
import QuestionnairePanel from './QuestionnariePanel'

const { Option } = Select

/**
 * アンケート結果の入力ページ
 *
 * Path: /questionnaire
 */
const QuestionnaireInputPage = () => {
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const prefectureCode = school?.attributes?.prefectureCode

  const [schoolGrade, setSchoolGrade] = useState(0)
  const [schoolClass, setSchoolClass] = useState(0)
  const [schoolClasses, setSchoolClasses] = useState<number[]>([])
  const [data, setData] = useState<undefined | Surveys['data']>()
  const [questionData, setQuestionData] = useState<Question[]>([])
  const [listQuestion, setListQuestion] = useState<number[]>([])
  const [questionExtra, setQuestionExtra] = useState<StudentQuestionExtra>({})
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const [total, setTotal] = useState(0)
  const { data: grades, loading: gradesLoading } = useSchoolGradesApiQuery()

  const { data: surveys, loading: surveysLoading } = useSchoolSurveysApiQuery({
    testYear: currentYear,
    schoolGrade,
    schoolClass,
    limit: pageSize,
    offset: (currentPage - 1) * pageSize,
  })

  useEffect(() => {
    setData(surveys?.data)
    setTotal(surveys?.total ?? 0)
  }, [surveys?.data, surveys?.total])

  useEffect(() => {
    if (school) {
      fetchSchoolClasses(school._id, currentYear).then((res) => {
        const schoolClasses = res.schoolClasses

        if (schoolClasses.length) {
          setSchoolClasses(schoolClasses)
        }
      })
    }
  }, [school])

  // Get questions
  useEffect(() => {
    if (prefectureCode === undefined || !school) return

    const questionIds = getCurrentPrefectureQuestions(
      prefectureCode,
      school._id,
      isElementarySchool,
    )

    if (questionIds.length === 0) return

    setListQuestion(questionIds)

    /**
     * To update this prefecture question list.
     */
    const prefectureQuestionObject: Record<string, Question> = {}

    for (const question of allQuestions) {
      if (questionIds.includes(question.id)) {
        // question is modified later, so copy question information to new object
        prefectureQuestionObject[question.id] = { ...question }
      }
      const extraQuestion = getExtraQuestion(
        school.attributes.schoolName,
        question,
      )
      if (question.id === 45 && extraQuestion) {
        setQuestionExtra(extraQuestion)
      }
    }

    /**
     * To reorder questions
     */
    let prefectureQuestionDetails: Question[] = []

    questionIds.forEach((id) => {
      if (!prefectureQuestionObject[id]) {
        console.error('not found question id:', id)
        throw new Error(
          'question ids and all questions are not matching! For example, question ID is 200, but in question list, there is no question with ID 200!',
        )
      }
      prefectureQuestionDetails.push(prefectureQuestionObject[id])
    })

    prefectureQuestionDetails = prefectureQuestionDetails.map(
      (value, index) => {
        value.question = `Q${index + 1} : ${value.question}`
        return value
      },
    )

    // customize questionnaire for elementary school of ibaraki prefecture
    if (
      prefectureCode === 8 &&
      isElementarySchool &&
      prefectureQuestionDetails?.length > 7
    ) {
      prefectureQuestionDetails[7].question =
        'Q8 : 1日にどのくらいテレビを見ますか（テレビゲームを含みます）'
    }

    setQuestionData(prefectureQuestionDetails)
  }, [school, isElementarySchool, prefectureCode])

  return (
    <Dashboard selectedMenu={3} navbar={t('アンケート')}>
      <div className="mt-3 flex flex-col justify-center items-center ml-20">
        <div style={{ minWidth: 930, maxWidth: '100vw' }}>
          <QuestionnairePanel noElipssis/>

          <div className="w-full space-x-2">
            <Select
              value={schoolGrade}
              onChange={setSchoolGrade}
              className="w-32 rounded-5px pl-10"
              dropdownAlign={{
                offset: [0, -2],
              }}
            >
              <Option key={'grade-default'} value={0}>
                {t('totalGrades')}
              </Option>
              {grades?.data?.map((v) => (
                <Option value={v.grade} key={v.grade}>
                  {t('個別学年', {
                    count: v.grade,
                    ordinal: !isUsingJp,
                  })}
                </Option>
              ))}
            </Select>

            <Select
              value={schoolClass}
              onChange={setSchoolClass}
              className="w-32 rounded-5px pl-10"
              dropdownAlign={{
                offset: [0, -2],
              }}
            >
              <Option key={'class-default'} value={0}>
                {t('全組')}
              </Option>
              {schoolClasses.map((_class) => {
                return (
                  <Option key={`class-${_class}`} value={_class}>
                    {t('個別組', {
                      count: _class,
                      ordinal: !isUsingJp,
                    })}
                  </Option>
                )
              })}
            </Select>
          </div>

          <div className="w-full mt-1">
            {t('生徒登録人数', {
              student: isElementarySchool ? '児童' : '生徒',
            })}
            : {surveys?.total}
          </div>
          {listQuestion.length && (
            <TableQuestion
              dataDefault={data}
              listQuestion={listQuestion}
              questionExtra={questionExtra}
              questionData={questionData}
              total={total}
              pageSize={pageSize}
              setPageSize={setPageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isLoading={gradesLoading || surveysLoading}
            />
          )}
        </div>
      </div>
    </Dashboard>
  )
}

export default QuestionnaireInputPage
