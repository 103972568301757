/**
 * @example
 * "123-444-5556"
 * "12-4442-5556"
 * "1234567890"
 * "12345678901"
 * "111"
 * "---"
 */
export const dashSymbolOrNumberRegex = /^[\d-]+$/

export const dashSymbolOnlyRegex = /^-+$/

export const checkIfValidPhone = (
  input: string | undefined | null,
): boolean => {
  const inputStr = input ?? ''

  return (
    dashSymbolOrNumberRegex.test(inputStr) &&
    !dashSymbolOnlyRegex.test(inputStr)
  )
}

/**
 * Convert ssoEmail to lowercase, just in case ssoEmail is not lowercase
 * Convert Japanese alphabet characters (like full-width alphanumeric characters)
 * to their English equivalents when processing the SSO email.
 * - Convert full-width alphanumeric characters to half-width (e.g., Ａ -> A)
 */
export const normalizeJpEmail = (email: string): string => {
  return email.toLowerCase().normalize('NFKC')
}

const emailRegex = /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const includeFullWidthEmailRegex =
  /^(?!.*\.\.)[a-zA-Z0-9\uFF01-\uFF5E._%+-]+@[a-zA-Z0-9\uFF01-\uFF5E.-]+\.[a-zA-Z]{2,}$/

/**
 * Validates email format. Rules:
 * - Must start and end with alphanumeric
 * - Can contain dots, plus signs, underscores, hyphens in between
 * - Domain must be valid
 * - TLD must be at least 2 characters
 * Invalid examples: "a@a.a"
 *
 * @example
 * Valid: "user+tag@domain.com", "test.name+filter@domain.co.jp"
 */
export const checkIfValidEmail = (input: string): boolean =>
  emailRegex.test(input)

/**
 * Validates email format including full-width characters. Rules:
 * - Can start and end with alphanumeric or full-width characters
 * - Can contain dots, plus signs, underscores, hyphens in between
 * - Domain must be valid
 * - TLD must be at least 2 characters
 * - Can contain full-width characters (Unicode range FF01-FF5E)
 * Invalid examples: "a@a.a"
 * Valid examples: "ｔｅｓｔ@domain.com", "test＠domain.com"
 *
 * @example
 * Valid: "user+tag@domain.com", "test.name+filter@domain.co.jp", "ｔｅｓｔ@domain.com"
 */
export const checkIfValidEmailIncludingFullWidth = (input: string): boolean =>
  includeFullWidthEmailRegex.test(input)
