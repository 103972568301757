import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Col, List, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAlphaStore } from '~/context'
import { getCurrentPrefectureQuestions } from '~/utils/questions'
import { allQuestions, getExtraQuestion } from '~/utils/questions/all-questions'
import {
  Question,
  StudentQuestionExtraText,
} from '~/utils/types'

/**
 * アンケート結果の入力ページ
 *
 */
const QuestionnairePanel = ({noElipssis}: {noElipssis?: boolean}) => {
  const { t } = useTranslation()

  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const prefectureCode = school?.attributes?.prefectureCode

  const [questionData, setQuestionData] = useState<Question[]>([])
  const [isCollapsed, setCollapsed] = useState(noElipssis === true)

  // Get questions
  useEffect(() => {
    if (prefectureCode === undefined || !school) return

    const questionIds = getCurrentPrefectureQuestions(
      prefectureCode,
      school._id,
      isElementarySchool,
    )

    if (questionIds.length === 0) return

    /**
     * To update this prefecture question list.
     */
    const prefectureQuestionObject: Record<string, Question> = {}

    for (const question of allQuestions) {
      if (questionIds.includes(question.id)) {
        // question is modified later, so copy question information to new object
        prefectureQuestionObject[question.id] = { ...question }
      }
    }

    /**
     * To reorder questions
     */
    let prefectureQuestionDetails: Question[] = []

    questionIds.forEach((id) => {
      if (!prefectureQuestionObject[id]) {
        console.error('not found question id:', id)
        throw new Error(
          'question ids and all questions are not matching! For example, question ID is 200, but in question list, there is no question with ID 200!',
        )
      }
      prefectureQuestionDetails.push(prefectureQuestionObject[id])
    })

    prefectureQuestionDetails = prefectureQuestionDetails.map(
      (value, index) => {
        value.question = `Q${index + 1} : ${value.question}`
        return value
      },
    )

    // customize questionnaire for elementary school of ibaraki prefecture
    if (
      prefectureCode === 8 &&
      isElementarySchool &&
      prefectureQuestionDetails?.length > 7
    ) {
      prefectureQuestionDetails[7].question =
        'Q8 : 1日にどのくらいテレビを見ますか（テレビゲームを含みます）'
    }

    setQuestionData(prefectureQuestionDetails)
  }, [school, isElementarySchool, prefectureCode])

  const renderQuestion = (questionData: Question, idx: number) => {
    if (!school?.attributes?.schoolName) return

    let answers: JSX.Element | undefined

    if (questionData.options) {
      answers = (
        <Row className="w-full" gutter={8}>
          {Object.keys(questionData.options).map((answer, idx) => {
            const value = questionData.options[answer]
            return (
              <Col key={idx}>
                <span className={isCollapsed ? 'hidden' : 'pl-10 block'}>
                  {value + (answer && `.${answer}`)}
                </span>
              </Col>
            )
          })}
        </Row>
      )
    }

    if (questionData.id === 45 && questionData.options) {
      const extra = getExtraQuestion(school.attributes.schoolName, questionData)

      if (extra) {
        const options: { value: string; name: string }[] = []
        const extraKeys = Object.keys(extra)

        for (const key of extraKeys) {
          const optVal = `${key}.${
            (extra[key] as StudentQuestionExtraText).name
          }`

          options.push({
            value: optVal,
            name: optVal,
          })
        }

        answers = (
          <Row className="w-full" gutter={8}>
            {<Select defaultValue={`1.${extra[1].name}`} options={options} />}
          </Row>
        )
      }
    }

    const showEllipsis = isCollapsed && idx === 0

    return (
      <List.Item
        style={{
          width: prefectureCode === 45 ? 860 : 800,
          borderStyle: 'none',
        }}
      >
        <>
          <div
            className={
              showEllipsis
                ? 'font-black collapsed-questions relative w-max '
                : 'font-black whitespace-pre-wrap'
            }
            {...{ ellipsis: '...' }}
          >
            {questionData.question}
          </div>
          {answers}
        </>
      </List.Item>
    )
  }

  const collapse = () => {
    setCollapsed(!isCollapsed)
  }

  return (
    <>
      <div
        className={`border border-primary rounded-xl px-10 py-2 ${
          noElipssis 
            ? isCollapsed && 'hidden'
            : isCollapsed && 'h-16 overflow-hidden'
        }`}
      >
        {school && (
          <List
            className="flex flex-col items-center space-y-1"
            itemLayout="vertical"
            dataSource={questionData}
            renderItem={(question, idx) => renderQuestion(question, idx)}
            bordered={false}
            pagination={{
              size: 'small',
              showTitle: false,
              hideOnSinglePage: true,
              showSizeChanger: false,
              pageSize: 4,
              position: 'bottom',
            }}
          />
        )}
      </div>
      <div className="flex justify-center items-center mt-2">
        <Button
          type="link"
          icon={
            isCollapsed ? (
              <DownOutlined style={{ verticalAlign: 'middle' }} />
            ) : (
              <UpOutlined style={{ verticalAlign: 'middle' }} />
            )
          }
          onClick={collapse}
        >
          {
            isCollapsed
              ? noElipssis ? t('質問を表示する') : t('質問をもっと見る')
              : t('質問を閉じる')
          }
        </Button>
      </div>
    </>
  )
}

export default QuestionnairePanel
