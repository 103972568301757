import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAlphaStore } from '~/context'
import MenuCard from '../cards/MenuCard'
import { Dashboard } from '../layout/Dashboard'

/**
 * /information
 */
const InformationPage = memo(() => {
  const history = useHistory()
  const { t } = useTranslation()
  const { school } = useAlphaStore()

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  return (
    <Dashboard
      selectedMenu={2}
      navbar={isElementarySchool ? t('児童管理') : t('生徒管理')}
    >
      <div className="grid-cols-2 grid mt-14 gap-4 text-black">
        <div className="flex justify-end">
          <MenuCard
            title={t(isElementarySchool ? '児童情報の登録' : '生徒情報の登録')}
            description={t(
              isElementarySchool
                ? '児童の登録をExcelファイルで行うことができます。利用開始時に登録をしてください。'
                : '生徒の登録をExcelファイルで行うことができます。利用開始時に登録をしてください。',
            )}
            onClick={() => history.push('/student_list')}
          />
        </div>

        <MenuCard
          title={t(
            isElementarySchool ? '児童のログイン管理' : '生徒のログイン管理',
          )}
          description={t(
            '登録した生徒がログインするためのサインインコードを表示します。',
            { student: isElementarySchool ? '児童' : '生徒' },
          )}
          onClick={() => history.push('/school_year_info')}
        />

        <div className="flex justify-end mt-2">
          <MenuCard
            title={t(isElementarySchool ? '児童情報の変更' : '生徒情報の変更')}
            description={t(
              isElementarySchool
                ? '登録した児童情報の変更や追加ができます。'
                : '登録した生徒情報の変更や追加ができます。',
            )}
            onClick={() => history.push('/students')}
          />
        </div>
      </div>
    </Dashboard>
  )
})

export default InformationPage
