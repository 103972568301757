import { CloseOutlined } from '@ant-design/icons'
import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { SchoolInvitationCode } from '~/alpha/core'
import { getFullName } from '~/school-utils/name'
import isJapanese from '~/utils/isJapanese'

export const QrList = ({
  students,
  schoolGrade,
  schoolClass,
  code,
  handleCancel,
}: {
  students: SchoolInvitationCode['students'] | any
  schoolGrade: number
  schoolClass: number
  code: string
  handleCancel: () => void
}) => {
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  return (
    <div>
      <div className="flex justify-center text-base font-black">
        {`${t('g年', {
          count: schoolGrade,
          ordinal: !isUsingJp,
        })} ${t('個別組', {
          count: schoolClass,
          ordinal: !isUsingJp,
        })}`}
      </div>

      <div className="absolute right-4 top-4 ">
        <CloseOutlined
          className="text-base color-blue"
          onClick={handleCancel}
        />
      </div>

      <div className="mx-5 my-5">
        <div className="my-4">
          <div className="grid grid-cols-3 gap-5 py-2">
            {students.map(
              ({
                attendanceNumber,
                familyName,
                givenName,
                studentInvitationCode,
              }) => (
                <div
                  className="p-6 border grid justify-center bg-white"
                  key={`${schoolGrade}-${schoolClass}-${attendanceNumber}`}
                >
                  <div className="flex flex-col space-y-2 items-center">
                    <QRCode
                      value={`${code}${attendanceNumber}${
                        studentInvitationCode ? `_${studentInvitationCode}` : ''
                      }`}
                    />

                    <div className="text-sm pt-2">{`${code}${attendanceNumber}${
                      studentInvitationCode ? `_${studentInvitationCode}` : ''
                    }`}</div>

                    <div className="text-lg">
                      {`${t('g年生', {
                        count: schoolGrade,
                        ordinal: !isUsingJp,
                      })} ${t('個別組', {
                        count: schoolClass,
                        ordinal: !isUsingJp,
                      })} ${t('n番', { num: attendanceNumber })}`}
                    </div>

                    <div>{`${t('名前')}${t('：')}${getFullName(
                      familyName,
                      givenName,
                      isUsingJp,
                    )}`}</div>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
