import { FC, memo } from 'react'
import NextIcon from '../icons/NextIcon'

interface MenuCardProps {
  title: string
  description: string
  onClick: () => void
}

const MenuCard: FC<MenuCardProps> = memo(({ title, description, onClick }) => {
  return (
    <div
      className="cursor-pointer rounded w-400px h-240px p-44px-20px"
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      }}
      onClick={onClick}
    >
      <div className="flex justify-between mb-8">
        <span className="font-bold text-xl">{title}</span>
        <NextIcon />
      </div>
      {description}
    </div>
  )
})

export default MenuCard
