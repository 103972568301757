import { CloseOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  FormInstance,
  Grid,
  InputNumber,
  Modal,
  Row,
} from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { parseDisplayValueToValidateNumber } from '~/utils/validations'
import { heightRules, InputConfig, weightRules } from '../constants'

interface TestDetailsModalProps {
  showDetailModal: boolean
  onCancel: () => void
  form: FormInstance
  onFinish: (values: any) => void
  loading: boolean
  getInputList: () => any
  renderInput: (input: InputConfig) => JSX.Element
  weightHeightTabTitleDefaultWidth: string
}

const { useBreakpoint } = Grid

export const TestDetailsModal = React.memo(
  ({
    showDetailModal,
    onCancel,
    form,
    onFinish,
    loading,
    getInputList,
    renderInput,
    weightHeightTabTitleDefaultWidth,
  }: TestDetailsModalProps) => {
    const { t } = useTranslation()
    const screens = useBreakpoint()

    return (
      <Modal
        visible={showDetailModal}
        width={screens.xxl ? '70vw' : screens.lg ? '85vw' : '90vw'}
        onCancel={onCancel}
        closeIcon={<CloseOutlined className="text-2xl color-blue-f" />}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row>
            <Col span={12} className="pr-2">
              {getInputList()
                .slice(0, 4)
                .map((input) => renderInput(input as unknown as InputConfig))}
            </Col>

            <Col span={12} className="pl-2">
              <Row className="space-x-2">
                <Col span={6}>
                  <Row>
                    <div
                      className={`h-5 bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black ${weightHeightTabTitleDefaultWidth}`}
                    >
                      {t('身長')}
                    </div>
                  </Row>

                  <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
                    <Col span={6}>
                      <div className="flex relative">
                        <Form.Item
                          className="mb-0-f"
                          name="height"
                          rules={heightRules(t)}
                          validateTrigger={['onSubmit', 'onChange']}
                        >
                          <InputNumber
                            size="small"
                            type="number"
                            inputMode="decimal"
                            step="any"
                            parser={parseDisplayValueToValidateNumber}
                          />
                        </Form.Item>
                        <div className="flex items-end absolute left-24 top-4">
                          <span className="text-10px">cm</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col span={6}>
                  <Row>
                    <div
                      className={`h-5 bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black ${weightHeightTabTitleDefaultWidth}`}
                    >
                      {t('体重')}
                    </div>
                  </Row>
                  <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
                    <Col span={6}>
                      <div className="flex relative">
                        <Form.Item
                          className="mb-0-f"
                          name="weight"
                          rules={weightRules(t)}
                          validateTrigger={['onSubmit', 'onChange']}
                        >
                          <InputNumber
                            size="small"
                            type="number"
                            inputMode="decimal"
                            step="any"
                            parser={parseDisplayValueToValidateNumber}
                          />
                        </Form.Item>
                        <div className="flex items-end absolute left-24 top-4">
                          <span className="text-10px">kg</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {getInputList()
                .slice(4)
                .map((input) => renderInput(input as unknown as InputConfig))}
            </Col>
          </Row>
          <Row justify="center">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="w-60"
                size="large"
                loading={loading}
              >
                {t('登録')}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    )
  },
)
