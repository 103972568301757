import './award.css'

import { Col, Row } from 'antd'
import { ITeacherSchool } from '~/types/api/alpha/teachers'
import { schoolHasEnduranceTest } from '~/utils'
import {
  awardTestKeysHasEnduranceRunOnly,
  awardTestKeysHasShuttleRunOnly,
} from '~/utils/test-const'
import { TStudent } from '../../../../../utils/types/api/res'

type Props = {
  school: ITeacherSchool
  testYear: number
  student: TStudent
  hasEnduranceRun: boolean
  isMobile: boolean
}

const AwardPerStudentPrinting = ({
  school,
  testYear,
  student,
  hasEnduranceRun,
  isMobile
}: Props) => {
  const attributes = student.attributes

  const testResult = attributes.testResults?.find(
    (item) => item.testYear === testYear,
  )

  const shuttleRunPoint = testResult?.shuttleRun?.points ?? 0
  const enduranceRunPoint = testResult?.enduranceRun?.points ?? 0

  const isShowEnduranceRunOnly = hasEnduranceRun
    ? enduranceRunPoint > shuttleRunPoint
    : false

  const testKeys = isShowEnduranceRunOnly
    ? awardTestKeysHasEnduranceRunOnly
    : awardTestKeysHasShuttleRunOnly

  const isEnduranceRun = schoolHasEnduranceTest(school.attributes)

  return (
    <div className={`relative award-print-container${isMobile ? '-sp' : ''}`}>
      <img
        alt=""
        src={
          isEnduranceRun
            ? isShowEnduranceRunOnly
              ? './award_high_school_endurance_run.png'
              : './award_high_school_shuttle_run.png'
            : './award_elementary_school_shuttle_run.png'
        }
        className="absolute"
      />

      {/* info on background */}
      <div className="pl-32 pt-72 font-black text-black">
        {/* student name info row */}
        <div className="award-student-info">
          <Col>
            {/* Row 1 */}
            <Row>{school.attributes.schoolName}</Row>

            {/* Row 2 */}
            <Row className="pt-4">
              <div className="pr-8">
                {attributes.schoolGrade}年 {attributes.schoolClass}組{' '}
                {attributes.schoolAttendanceNumber}番
              </div>
              <div>
                {attributes.familyName}
                {attributes.givenName}
              </div>
            </Row>
          </Col>
        </div>

        <div className="text-center award-row-3-4">
          {/* Row 3: test value row */}
          <Row>
            {testKeys.map((key: string) => (
              <Col key={key} className="award-point">
                {testResult ? testResult[key]?.value : null}
              </Col>
            ))}
          </Row>

          {/* Row 4: point row */}
          <Row className="award-row-4">
            {testKeys.map((key: string) => (
              <Col key={key} className="award-point">
                {testResult ? testResult[key]?.points : null}
              </Col>
            ))}

            <Col key="total" className="award-point">
              {testResult ? testResult?.points : null}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default AwardPerStudentPrinting
