import axios from 'axios'

export const fetchSchoolClasses = (
  schoolId: string,
  testYear: number,
): Promise<{ schoolClasses: number[] }> => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_REST_API_URL}/alpha/v1/school/classes/count`,
      )
      .then((res: { data: { count: number } }) => {
        const count = res.data.count

        const classes = count
          ? Array.from({ length: count }, (_, i) => i + 1)
          : []
        resolve({ schoolClasses: classes })
      })
  })
}
