import { round } from '~/utils/number'

export const percentage = (value: number, total: number) => {
  return round((value / total) * 100, 1)
}

/**
 * Chunk array of object / array / primitive type.
 * Return an empty array for size 0
 * Return the same array when size is greater than array length
 * Return the array chunked with mutated object
 *
 * @param input - array of object / array
 * @param size - chunk size
 * @returns chunked array with mutated object
 */
export const chunkMutated = <T>(input: T[], size: number): T[][] => {
  if (input.length <= size) {
    return [input]
  }

  if (size <= 0) {
    return []
  }

  const chunkedArray: T[][] = []

  for (let i = 0; i < input.length; i += size) {
    chunkedArray.push(input.slice(i, i + size))
  }

  return chunkedArray
}
