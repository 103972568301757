import { Button } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SignInLayout } from '../layout/SignInLayout'

const SignInPage = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleSignInBtn = useCallback(async () => {
    history.push('/register')
  }, [history])

  return (
    <SignInLayout>
      <div className="flex justify-center relative">
        <div className="absolute bottom-36">
          <SignInLayout.AlphaLogo />
        </div>

        <Button
          className="w-60"
          type="primary"
          size="large"
          onClick={handleSignInBtn}
        >
          {t('始める')}
        </Button>
      </div>
    </SignInLayout>
  )
}

export default SignInPage
