import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const VideoQualitySettingsLink = memo(() => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className="w-full text-right pb-5">
      <span className="text-black">{t('動画の再生が遅い場合は、_')}</span>

      <a
        href="/setting"
        onClick={(e) => {
          e.preventDefault()
          history.push('/setting')
        }}
        className="hover:underline"
      >
        {t('画質設定')}
      </a>

      <span className="text-black">{t('_から低画質をお試しください。')}</span>
    </div>
  )
})

export default VideoQualitySettingsLink
