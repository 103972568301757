import { exerciseProgramVideosBaseUrlLength } from './const'

export const getLowUrl = (url: string) => {
  const videoId = url.slice(
    exerciseProgramVideosBaseUrlLength,
    exerciseProgramVideosBaseUrlLength + 3,
  )

  return `https://alpha-2023.s3.ap-northeast-1.amazonaws.com/example_videos_low_quality/${videoId}.mp4`
}
