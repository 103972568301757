import './MeasurableModal.css'

import { Button, Modal } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const MeasurableModal: FC<{
  isModalOpen: boolean | object
  setIsModalOpen: (isModalOpen: boolean) => void
  handleOk: () => void
}> = ({ isModalOpen, setIsModalOpen, handleOk }) => {
  const { t } = useTranslation()

  return (
    <Modal
      visible={!!isModalOpen}
      onOk={handleOk}
      onCancel={() => setIsModalOpen(false)}
      centered
      footer={null}
      closeIcon={null}
      closable={false}
    >
      <div className="p-4 ml-2 text-black text-base">
        {t('「今年度測定なし」の設定を解除して値を入力しますか？')}
      </div>

      <div className="flex justify-center my-6 space-x-6">
        <Button type="primary" size="large" onClick={handleOk} className="w-28">
          {t('入力')}
        </Button>

        <Button
          type="ghost"
          size="large"
          onClick={() => setIsModalOpen(false)}
          className="w-28"
        >
          {t('キャンセル')}
        </Button>
      </div>
    </Modal>
  )
}

export default MeasurableModal
