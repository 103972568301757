import { CheckOutlined } from '@ant-design/icons'

interface CompletionLayoutProps {
  message: string
  button?: JSX.Element
  hasNullValue: boolean
}

export default function QuestionnaireCompletionLayout({
  message,
  hasNullValue,
  button = undefined,
}: CompletionLayoutProps) {
  return (
    <div className="flex items-center flex-col justify-center h-screen">
      <div className="flex items-center justify-center w-40 h-40 rounded-full ring-8 ring-gray-200 ring-offset-8 ring-offset-gray-200 check-outlined-wrapper">
        <CheckOutlined className="check-outlined-icon" />
      </div>

      <div className="mt-8 text-base font-bold">{message}</div>
      {hasNullValue && <div className="w-full flex pb-4 justify-center pt-4">
        <div className="w-624px text-black p-4 text-center text-xl" style={{ backgroundColor: '#fef2cc'  }}>
          回答できない選択肢は未回答（空欄）として登録されました。<br />
          入力画面で正しい値を入力してください。<br />
        </div>
      </div>}

      {button && <div className="mt-7">{button}</div>}
    </div>
  )
}
