import './DropdownMenu.css'

import { UpOutlined } from '@ant-design/icons'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import MenuIcon2 from '~/alpha/components/icons/MenuIcon2'
import { useAlphaStore } from '~/context'
import { useFirstTimeStore } from '~/context/is-first-time-login'
import { useMenuStore } from '~/context/menu'
import { menuList } from '../navigation'

const DropdownMenu: FC = memo(() => {
  const { isFirstTimeLogin, setIsFirstTimeLogin } = useFirstTimeStore()
  const { menuId } = useMenuStore()
  const { showUnloadPopup, setNextUrl } = useAlphaStore()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(isFirstTimeLogin)

  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  useEffect(() => {
    const closeDropdown = (event: Event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as HTMLButtonElement)
      ) {
        setIsOpen(false)
      }
    }

    // Add event listener to detect clicks outside of the dropdown
    document.addEventListener('mousedown', closeDropdown)

    return () => {
      // Cleanup the event listener when the component unmounts
      document.removeEventListener('mousedown', closeDropdown)
    }
  }, [])

  const handleClose = () => {
    setIsOpen(false)
    if (isFirstTimeLogin) setIsFirstTimeLogin(false)
  }

  // {
  //   key: 'home',
  //   title: 'ホーム',
  //   tabIndex: 1,
  // },
  // {
  //   key: 'information',
  //   title: '情報',
  //   tabIndex: 2,
  // },
  let MenuItems: JSX.Element[] = [
    <div
      className={`text-white dropdown-item font-bold text-center${
        menuId === 1 ? ' dropdown-item-selected' : ''
      }`}
      key={1}
      onClick={() => {
        setNextUrl('/home')
        if (!showUnloadPopup?.()) {
          handleClose()
          history.push('/home')
        }
      }}
    >
      {t('ホーム')}
    </div>,

    <div
      className={`text-white dropdown-item font-bold text-center${
        menuId === 2 ? ' dropdown-item-selected' : ''
      }`}
      key={2}
      onClick={() => {
        setNextUrl('/information')
        if (!showUnloadPopup?.()) {
          handleClose()
          history.push('/information')
        }
      }}
    >
      {isElementarySchool ? t('児童管理') : t('生徒管理')}
    </div>,
  ]

  MenuItems = MenuItems.concat(
    menuList.map((item) => (
      <div
        className={`text-white dropdown-item font-bold text-center${
          menuId === item.tabIndex ? ' dropdown-item-selected' : ''
        }`}
        key={item.tabIndex}
        onClick={() => {
          setNextUrl(`/${item.key}`)
          if (!showUnloadPopup?.()) {
            handleClose()
            history.push(`/${item.key}`)
          }
        }}
      >
        {t(item.title)}
      </div>
    )),
  )

  MenuItems.push(
    <div
      className={'text-white dropdown-item font-bold text-center'}
      key="6"
      onClick={() => {
        setNextUrl(
          'https://alpha2019.notion.site/ALPHA-739c7668999041d298e1e0af147e17e9',
        )
        if (!showUnloadPopup?.()) {
          handleClose()
          window.open(
            'https://alpha2019.notion.site/ALPHA-739c7668999041d298e1e0af147e17e9',
            '_blank',
          )
        }
      }}
    >
      <span style={{ whiteSpace: 'pre-line' }}>{t('使い方')}</span>
    </div>,

    <div
      className={'text-white dropdown-item font-bold text-center'}
      key="7"
      onClick={() => {
        setNextUrl('https://pestalozzi-tech.com/contact/')
        if (!showUnloadPopup?.()) {
          handleClose()
          window.open('https://pestalozzi-tech.com/contact/', '_blank')
        }
      }}
    >
      <span style={{ whiteSpace: 'pre-line' }}>{t('問い合わせ')}</span>
    </div>,

    <div
      className={`text-white dropdown-item font-bold text-center${
        menuId === 8 ? ' dropdown-item-selected' : ''
      }`}
      key="8"
      onClick={() => {
        setNextUrl('/setting')
        if (!showUnloadPopup?.()) {
          handleClose()
          history.push('/setting')
        }
      }}
    >
      {t('設定')}
    </div>,
    <div
      className={'text-white dropdown-item font-bold text-center'}
      key="9"
      onClick={() => {
        setNextUrl('/logout')
        if (!showUnloadPopup?.()) {
          handleClose()
          history.push('/logout')
        }
      }}
    >
      <span style={{ whiteSpace: 'pre-line' }}>{t('ログアウト')}</span>
    </div>,
  )

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
    if (isFirstTimeLogin) setIsFirstTimeLogin(false)
  }

  return (
    <div
      className="dropdown-container"
      ref={dropdownRef}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="dropdown-button px-4" onClick={toggleDropdown}>
        <MenuIcon2 />
      </div>

      <div
        className={`dropdown-menu bg-black flex flex-col transition-opacity duration-200 ease-in-out ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      >
        {MenuItems}

        <div
          className="dropdown-item-close cursor-pointer flex items-center justify-center bg-white hover:bg-blue-50"
          onClick={() => {
            handleClose()
          }}
        >
          <UpOutlined className="px-2" />
          {t('閉じる')}
        </div>
      </div>
    </div>
  )
})

export default DropdownMenu
