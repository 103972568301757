import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { MetaStudent } from '~/alpha/core'
import { getFullName } from '~/school-utils/name'
import isJapanese from '~/utils/isJapanese'

/**
 * Students list has 3 columns,
 * The input students length is 3.
 */
export const PrintingFor9 = ({
  students,
  schoolGrade,
  schoolClass,
  code,
  isUsingJpRuby,
}: {
  students: MetaStudent[]
  schoolGrade: number
  schoolClass: number
  code: string
  isUsingJpRuby: boolean
}) => {
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  return (
    <div className="mx-5 page-break-auto">
      <div className="grid grid-cols-3 gap-5 py-2">
        {students.map(
          ({
            attendanceNumber,
            familyName,
            givenName,
            studentInvitationCode,
          }) => {
            const value = `${code}${attendanceNumber}${
              studentInvitationCode ? `_${studentInvitationCode}` : ''
            }`

            return (
              <div
                className="p-2 border grid justify-center bg-white"
                key={value}
              >
                <div className="flex flex-col space-y-2 items-center">
                  <div className="flex flex-col items-center text-black">
                    {isUsingJpRuby ? (
                      <>
                        <div>
                          サインイン
                          <ruby>
                            <span>画面</span>
                            <rt>がめん</rt>
                          </ruby>
                          からQRコードを
                        </div>

                        <div>
                          <ruby>
                            <span>読</span>
                            <rt>よ</rt>
                          </ruby>
                          み
                          <ruby>
                            <span>取</span>
                            <rt>と</rt>
                          </ruby>
                          ってください
                        </div>
                      </>
                    ) : (
                      <>
                        <div>{t('サインイン画面からQRコードを')}</div>
                        <div>{t('読み取ってください')}</div>
                      </>
                    )}
                  </div>

                  <QRCode value={value} />

                  <div className="text-10px">{value}</div>

                  <div className={isUsingJp ? 'text-lg' : ''}>
                    {`${t('g年生', {
                      count: schoolGrade,
                      ordinal: !isUsingJp,
                    })} ${t('個別組', {
                      count: schoolClass,
                      ordinal: !isUsingJp,
                    })} ${t('n番', { num: attendanceNumber })}`}
                  </div>

                  <div>{`${t('名前')}${t('：')}${getFullName(
                    familyName,
                    givenName,
                    isUsingJp,
                  )}`}</div>
                </div>
              </div>
            )
          },
        )}
      </div>
    </div>
  )
}
