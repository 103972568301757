import React, { AnchorHTMLAttributes } from 'react'

export type AnchorProps = {
  href: string
}

const Anchor: React.FC<
  AnchorProps & AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ href, children }) => {
  return (
    <a
      className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 text-blue-700 underline font-semibold"
      href={href}
    >
      {children}
    </a>
  )
}

export default Anchor
