import { NavigationItem } from '@sergeimeza/uikit-react/Elements/Navigation/NavigationItem'

export const navigation: NavigationItem[] = [
  {
    name: '身体力テスト',
    to: '/home',
    current: true,
  },
  {
    name: '設定',
    current: false,
    children: [
      {
        name: '学校情報',
        to: '/settings/school',
      },
      {
        name: '学年情報',
        to: '/settings/grades',
      },
      {
        name: '生徒情報を登録',
        to: '/settings/students',
      },
      {
        name: 'アンケート設定',
        to: '/settings/questionnaire',
      },
    ],
  },
  {
    name: '測定結果',
    current: false,
    children: [
      {
        name: '測定結果を入力',
        to: '/tests/input',
      },
      {
        name: '測定結果を閲覧',
        to: '/tests/result',
      },
      {
        name: '測定結果を印刷',
        to: '/tests/print',
      },
    ],
  },
  {
    name: 'アンケート',
    current: false,
    children: [
      {
        name: 'アンケートを入力・確認',
        to: '/questionnaires/input',
      },
      {
        name: 'アンケート結果を閲覧',
        to: '/questionnaires/result',
      },
    ],
  },
  {
    name: '集計レポート',
    to: '/reports',
    current: false,
  },
  {
    name: 'その他',
    current: false,
    children: [
      {
        name: '先生一覧',
        to: '/other/teachers',
      },
    ],
  },
]

export type DashboardNavGraphItem = {
  key: string
  title: string
  tabIndex: number | undefined
}

/**
 * Translated when using.
 */
export const menuList: DashboardNavGraphItem[] = [
  // {
  //   key: 'home',
  //   title: 'ホーム',
  //   tabIndex: 1,
  // },
  // {
  //   key: 'information',
  //   title: '情報',
  //   tabIndex: 2,
  // },
  {
    key: 'input',
    title: '入力',
    tabIndex: 3,
  },
  {
    key: 'result',
    title: '結果',
    tabIndex: 4,
  },
  {
    key: 'movie',
    title: '動画',
    tabIndex: 5,
  },
]

/**
 * Translated when using.
 */
// export const dashboardNavGraphItems: DashboardNavGraphItem[] = [
// {
//   key: 'home',
//   title: 'ホーム',
//   tabIndex: 1,
//   subIndex: undefined,
// },
// {
//   key: 'information',
//   title: '情報',
//   tabIndex: 2,
//   subIndex: undefined,
// },
// {
//   key: 'input',
//   title: '入力',
//   tabIndex: 3,
//   subIndex: undefined,
// },
// {
//   key: 'result',
//   title: '結果',
//   tabIndex: 4,
//   subIndex: undefined,
// },
// {
//   key: 'movie',
//   title: '動画',
//   tabIndex: 5,
//   subIndex: undefined,
// },

// {
//   key: 'teacher_list',
//   title: '先生一覧',
//   tabIndex: undefined,
//   subIndex: 2,
// },
// {
//   key: 'student_list',
//   title: '生徒登録', //生徒一覧
//   tabIndex: undefined,
//   subIndex: 2,
// },
// {
//   key: 'students',
//   title: '修正・削除・追加',
//   tabIndex: undefined,
//   subIndex: 2,
// },
// {
//   key: 'date_of_birth',
//   title: '生年月日',
//   tabIndex: undefined,
//   subIndex: 3,
// },
// {
//   key: 'upload-excel',
//   title: 'エクセルでアップロード',
//   tabIndex: undefined,
//   subIndex: 3,
// },
// {
//   key: 'school_year_info',
//   title: 'サインインコード', //学年情報
//   tabIndex: undefined,
//   subIndex: 2,
// },

// {
//   key: 'test',
//   title: '体力テスト',
//   tabIndex: undefined,
//   subIndex: 3,
// },
// {
//   key: 'test_result',
//   title: '体力テスト一覧',
//   tabIndex: undefined,
//   subIndex: 4,
// },
// {
//   key: 'test_result_upload',
//   title: '測定結果を\nエクセルでアップロード',
//   tabIndex: undefined,
//   subIndex: 3,
// },

// {
//   key: 'questionnaire',
//   title: 'アンケート', //アンケートを入力・確認
//   tabIndex: undefined,
//   subIndex: 3,
// },
// {
//   key: 'questionnaire_result',
//   title: 'アンケート一覧',
//   tabIndex: undefined,
//   subIndex: 4,
// },
// {
//   key: 'questionnaire_upload',
//   title: 'アンケート結果を\nエクセルでアップロード',
//   tabIndex: undefined,
//   subIndex: undefined,
// },
// {
//   key: 'test_report',
//   title: '集計結果',
//   tabIndex: undefined,
//   subIndex: 4,
// },

// {
//   key: 'contact',
//   title: '使い方を見る',
//   tabIndex: undefined,
//   url: 'https://pestalozzi-tech.com/contact/',
//   subIndex: undefined,
// },
// {
//   key: 'logout',
//   title: 'ログアウト',
//   tabIndex: undefined,
//   subIndex: undefined,
// },

// {
//   key: 'register',
//   title: 'ユーザー情報登録',
//   tabIndex: undefined,
//   subIndex: undefined,
// },
// {
//   key: '',
//   title: 'サインイン',
//   tabIndex: undefined,
//   subIndex: undefined,
// },
// {
//   key: 'advise',
//   title: '体力テストお手本動画',
//   tabIndex: undefined,
//   subIndex: 5,
// },
// {
//   key: 'exercises',
//   title: '授業で使える運動コンテンツ一覧',
//   tabIndex: undefined,
//   subIndex: 5,
// },
// {
//   key: 'exercise_content',
//   title: '集計結果から最適化された運動コンテンツ',
//   tabIndex: undefined,
//   subIndex: 5,
// },
// {
//   key: 'exercise_program',
//   title: '個人でできる運動プログラム',
//   tabIndex: undefined,
//   subIndex: 5,
// },
// {
//   key: 'import_question',
//   title: '個人でできる運動プログラム',
//   tabIndex: undefined,
//   subIndex: 5,
// },
// {
//   key: 'import_question_prefecture',
//   title: '個人でできる運動プログラム',
//   tabIndex: undefined,
//   subIndex: 5,
// },
// ]
