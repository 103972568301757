import { Row } from 'antd'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

interface SchoolHeaderProps {
  school: {
    attributes: {
      schoolName: string
      schoolAddress: string
    }
  }
  teacher: {
    familyName: string
    givenName: string
  }
}

export const SchoolHeader: React.FC<SchoolHeaderProps> = memo(
  ({ school, teacher }) => {
    const { t } = useTranslation()

    return (
      <Row className="p-3">
        <div className="flex w-full flex-col">
          <div className="text-base font-bold text-center">
            {school.attributes.schoolName}
          </div>

          <div className="my-1 text-xs font-bold text-center">
            {school.attributes.schoolAddress}
          </div>

          <div className="mt-3 mb-7 text-sm font-bold text-center">
            {t('先生', {
              familyName: teacher.familyName,
              givenName: teacher.givenName,
            })}
          </div>
        </div>
      </Row>
    )
  },
)

export default SchoolHeader
